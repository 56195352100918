import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class PeriodService extends CentralService {

  protected resource: string = "school_diurnal_period";  

  getAllWithClasses(params: any = undefined): Observable<any> {
    return this.getAll({...params, include: 'classes'});
  }
  getSpecificWithClasses(id: any, params: any = undefined): Observable<any> {
    return this.getSpecific(id, {...params, include: 'classes'});
  }
  
}
