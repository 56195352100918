<div  *ngIf="loading || error || !data?.length" class="text-center py-5">
    <div *ngIf="loading && !error" class="spinner-border text-primary spinner-border-lg p-5" role="status">
        <span class="sr-only"></span>
    </div>

    <ng-container *ngIf="!loading && (!data || !data?.length) && !error">
        <img style="max-width: 30%;" src="../../../assets/svg/nothing.svg">
        <div class="h1 text-primary">
            {{ title ? (' لا توجد ' + title) : 'لا توجد بيانات' }}
        </div>
    </ng-container>

    <ng-container *ngIf="error">
        <img style="max-width: 30%;" src="../../../assets/svg/error.svg">
        <div class="h1 text-primary">
            حدث خطأ
        </div>
    </ng-container>

</div>