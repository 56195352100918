import { LOCALE_ID, NgModule } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SuperAdminModule } from './superadmin/superadmin.module';
import { SharedModule } from './shared.module';

import { LoggedInRootScreenComponent } from './screens/logged-in-root-screen/logged-in-root-screen.component';
import { LoginScreenComponent } from './screens/auth/login-screen/login-screen.component';
import { MoudSidebarComponent } from './components/moudsidebar/moudsidebar.component';
import { HttpErrorInterceptor } from './services/interceptor.service';
import { ChartsModule } from 'ng2-charts';

import { ClassMainScreenComponent } from './screens/class/class-main-screen/class-main-screen.component';
import { StudentGuardianMainScreenComponent } from './screens/studentAndGuardian/student-guardian-main-screen/student-guardian-main-screen.component';
import { NotificationsMainScreenComponent } from './screens/notifications/notifications-main-screen/notifications-main-screen.component';
import { InquiriesMainScreenComponent } from './screens/inquiries/inquiries-main-screen/inquiries-main-screen.component';
import { NewsMainScreenComponent } from './screens/news/news-main-screen/news-main-screen.component';
import { PeriodsMainScreenComponent } from './screens/periods/periods-main-screen/periods-main-screen.component';

import { ClassFormModalComponent } from './screens/class/class-form-modal/class-form-modal.component';
import { StudentFormModalComponent } from './screens/studentAndGuardian/student-form-modal/student-form-modal.component';
import { GuardianFormModalComponent } from './screens/studentAndGuardian/guardian-form-modal/guardian-form-modal.component';
import { GuardianUploadModalComponent } from './screens/studentAndGuardian/guardian-upload-modal/guardian-upload-modal.component';
import { InquiriesFormModalComponent } from './screens/inquiries/inquiries-form-modal/inquiries-form-modal.component';
import { NewsFormModalComponent } from './screens/news/news-form-modal/news-form-modal.component';
import { PeriodsFormModalComponent } from './screens/periods/periods-form-modal/periods-form-modal.component';
import { AlertsMainScreenComponent } from './screens/alerts/alerts-main-screen/alerts-main-screen.component';
import { AlertsPlaybackScreenComponent } from './screens/alerts/alerts-playback-screen/alerts-playback-screen.component';
import { NgxEditorModule } from 'ngx-editor';
import { NotificationFormModalComponent } from './screens/notifications/notification-form-modal/notification-form-modal.component';
import { ClassTableComponent } from './screens/class/class-table/class-table.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import { MoudUploaderComponent } from './components/moud-uploader/moud-uploader.component';
import localeLy from '@angular/common/locales/ar-LY';
registerLocaleData(localeLy);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoggedInRootScreenComponent,
    LoginScreenComponent,
    MoudSidebarComponent,
    MoudUploaderComponent,
    ClassMainScreenComponent,
    ClassFormModalComponent,
    StudentGuardianMainScreenComponent,
    StudentFormModalComponent,
    GuardianFormModalComponent,    
    GuardianUploadModalComponent,
    NotificationsMainScreenComponent,
    InquiriesMainScreenComponent,
    InquiriesFormModalComponent,
    NewsMainScreenComponent,
    NewsFormModalComponent,
    PeriodsMainScreenComponent,
    PeriodsFormModalComponent,
    AlertsMainScreenComponent,
    AlertsPlaybackScreenComponent,
    InquiriesMainScreenComponent,
    InquiriesFormModalComponent,
    NotificationFormModalComponent,
    ClassTableComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    SuperAdminModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    ChartsModule,
    NgxEditorModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyALx4hMT18FBezDhJUJgjemtDB5-gC5qKI',
      authDomain: 'hudhud-2c7bc.firebaseapp.com',
      projectId: 'hudhud-2c7bc',
      storageBucket: 'hudhud-2c7bc.appspot.com',
      messagingSenderId: '66195259034',
      appId: '1:66195259034:web:2435e20493bd9463c82923'
    }),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    DatePipe,
    { provide: LOCALE_ID, useValue: "ar-LY" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
