<div class="container">
    <div class="row">
        <div class="col-11 my-5 mx-auto">
            <div class="h3">{{ pageTitle }}</div>
            <div class="btn btn-lg btn-primary p-3"
                (click)="openResourceModal(resourceModal, 'add')">
                إضافة فصل <i class="fas fa-plus mx-2"></i>
            </div>
        </div>
    </div>
    
    <div class="col-11 mx-auto">
        <div class="card overflow-auto p-3">
            <div class="row">
                <div class="col-2">
                </div>
            </div>
            <div class="row my-3" *ngIf="resourceList && !isLoading && !isError">
                <div class="col-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item"
                            role="presentation">
                            <button class="nav-link active" id="level1-tab" type="button"
                                role="tab" data-bs-toggle="tab" data-bs-target="#level1">
                                الإبتدائي
                            </button>
                        </li>
                        <li class="nav-item"
                            role="presentation">
                            <button class="nav-link" id="level2-tab" type="button"
                                role="tab" data-bs-toggle="tab" data-bs-target="#level2">
                                الإعدادي
                            </button>
                        </li>
                        <li class="nav-item"
                            role="presentation">
                            <button class="nav-link" id="level3-tab" type="button"
                                role="tab" data-bs-toggle="tab" data-bs-target="#level3">
                                الثانوي
                            </button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div id="level1" role="tabpanel" class="tab-pane fade show active">
                            
                            <div class="accordion" id="accordionData1">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year1">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear1">
                                            العام 1
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear1" data-bs-parent="#accordionData1">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'1'" [year]="'1'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year2">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear2">
                                            العام 2
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear2" data-bs-parent="#accordionData1">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'1'" [year]="'2'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year3">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear3">
                                            العام 3
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear3" data-bs-parent="#accordionData1">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'1'" [year]="'3'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year4">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear4">
                                            العام 4
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear4" data-bs-parent="#accordionData1">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'1'" [year]="'4'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year5">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear5">
                                            العام 5
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear5" data-bs-parent="#accordionData1">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'1'" [year]="'5'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year6">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear6">
                                            العام 6
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear6" data-bs-parent="#accordionData1">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'1'" [year]="'6'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>                          
                            </div>
                            
                        </div>
                        
                        <div id="level2" role="tabpanel" class="tab-pane fade">
                            
                            <div class="accordion" id="accordionData2">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year7">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear7">
                                            العام 7
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear7" data-bs-parent="#accordionData2">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'2'" [year]="'1'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year8">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear8">
                                            العام 8
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear8" data-bs-parent="#accordionData2">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'2'" [year]="'2'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year9">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear9">
                                            العام 9
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear9" data-bs-parent="#accordionData2">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'2'" [year]="'3'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div id="level3" role="tabpanel" class="tab-pane fade">
                            
                            <div class="accordion" id="accordionData3">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year10">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear10">
                                            العام 10
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear10" data-bs-parent="#accordionData3">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'3'" [year]="'1'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year11">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear11">
                                            العام 11
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear11" data-bs-parent="#accordionData3">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'3'" [year]="'2'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="year12">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseYear12">
                                            العام 12
                                        </button>
                                    </h2>
                                    <div class="accordion-collapse collapse"
                                        id="collapseYear12" data-bs-parent="#accordionData3">
                                        <div class="accordion-body">
                                            <app-class-table
                                                [resourceList]="resourceList"
                                                (clickedEdit)="openResourceModal(resourceModal, 'edit', $event)"
                                                (clickedDelete)="openResourceModal(deleteModal, 'delete', $event)"
                                                [level]="'3'" [year]="'3'">
                                            </app-class-table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
                            
            <loader-section
                [loading]="isLoading"
                [data]="[{},{},{}]"
                [error]="isError">
            </loader-section>
        </div>
    </div>

    <ng-template #resourceModal let-modal>
        <app-class-form-modal
            (closeAction)="modal.dismiss('closed')"
            [selectedResourceId]="selectedResourceId">
        </app-class-form-modal>
    </ng-template>

    <ng-template #deleteModal let-modal>
        <confirm-query-modal
            (confirm)="closeConfirmModal(modal, true)"
            (cancel)="closeConfirmModal(modal, false)"
            (close)="modal.close(e)"
            (dismiss)="modal.dismiss(e)">
        </confirm-query-modal>
    </ng-template>

</div>