import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'moud-uploader',
  templateUrl: './moud-uploader.component.html',
  styleUrls: ['./moud-uploader.component.scss']
})

export class MoudUploaderComponent implements OnInit {

  @Input() required: boolean;
  @Input() loading: boolean;
  
  @Input() inputValue: any;
  @Output() inputValueChange: EventEmitter<any> = new EventEmitter<any>();
  
  @Input() inputFileList: FileList;
  @Output() inputFileListChange: EventEmitter<FileList> = new EventEmitter<FileList>();

  @Input() backgroundImage: string;
  
  constructor() {}

  ngOnInit(): void {
    this.getImageIfAny(this.inputValue, this.inputFileList);
  }

  getImageIfAny(file: any | string, files: FileList = undefined): void {
    // Emit the file binaries
    this.inputFileListChange.emit(files);

    // Get the image and set it as the background
    if(!file && (files && !files[0]))
      this.backgroundImage = '';

    if (files && files[0]) {
      var reader  = new FileReader();
      reader.onload = (e) => {
          this.backgroundImage = `url(${ e.target.result})`;
      }
      reader.readAsDataURL(files[0]);
    } else if (typeof file == 'string') {
      if(file) this.backgroundImage = `url(${file})`;
    }
    
  }
}
