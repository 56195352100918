
const production: boolean = true;

const prod_env = {
  production: true,
  // STORAGE_URL: 'http://127.0.0.1:8000/storage',
  // API_URL: 'http://127.0.0.1:8000/api/v1',
  // AUTH_URL: 'http://127.0.0.1:8000/api/v1/auth/admin-login'
  STORAGE_URL: 'https://hudhud-api.mostanad.ly/storage',
  API_URL: 'https://hudhud-api.mostanad.ly/api/v1',
  AUTH_URL: 'https://hudhud-api.mostanad.ly/api/v1/auth/admin-login'
};

const dev_env = {
  production: false,
  STORAGE_URL: 'https://hudhud-api.mostanad.ly/storage',
  API_URL: 'https://hudhud-api.mostanad.ly/api/v1',
  AUTH_URL: 'https://hudhud-api.mostanad.ly/api/v1/auth/admin-login'
};

export const environment = (production ? prod_env : dev_env);
