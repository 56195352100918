import { Injectable } from '@angular/core';
import { CentralService } from './central.service';

@Injectable({
  providedIn: 'root'
})

export class UploadExcelService extends CentralService {

  protected resource: string = "import";

}
