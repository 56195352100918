<div class="fluid-container p-3">
    <div class="row">
        <div class="col-11 mx-auto">
            <h3>إضافة مواعيد الحضور والانصراف</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-11 mx-auto">
            <form #resourceForm="ngForm" (submit)="submitResourceForm(resourceForm)">
                <div class="row">                    
                    <div class="col-12 mx-auto mx-3 my-4">
                        <input required dir="rtl"
                            type="text"
                            name="name"
                            [(ngModel)]="resourceData.name"
                            placeholder="الإسم"
                            class="form-control">
                    </div>
                </div>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active"
                            [disabled]="loading"
                            id="tab-one"
                            data-bs-toggle="tab"
                            data-bs-target="#one"
                            type="button" role="tab">
                            الجدول
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link"
                            [disabled]="loading"
                            id="tab-two"
                            data-bs-toggle="tab"
                            data-bs-target="#two"
                            type="button" role="tab">
                            الفصول
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">

                    <div class="tab-pane fade show active" id="one" role="tabpanel">
                        <div class="row">
                            <div class="col-12 mx-auto mx-3">
                                <div class="row m-3 text-center">
                                    <div class="col-4">
                                    </div>
                                    <div class="col-4">
                                        {{ 'حضور' }}
                                    </div>
                                    <div class="col-4">
                                        {{ 'انصراف' }}
                                    </div>
                                </div>

                                <div class="row m-3" *ngFor="let row of timetable; index as i">
                                    <div class="col-4 px-4">
                                        <div class="form-check">
                                            <div class="btn w-100"
                                                [class.btn-primary]="row.enabled"
                                                [class.btn-outline-primary]="!row.enabled"
                                                (click)="row.enabled = !row.enabled">
                                                {{ row.day_ar }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control"
                                            [name]="'timetable['+i+'].starts_at'"
                                            [(ngModel)]="timetable[i].starts_at">
                                    </div>
                                    <div class="col-4">
                                        <input type="time" class="form-control"
                                            [name]="'timetable['+i+'].ends_at'"
                                            [(ngModel)]="timetable[i].ends_at">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="two" role="tabpanel">
                        <div class="row">
                            <div class="col-6 limited-scroll longer-limit">
                                <div class="row mb-3">
                                    <div class="col-12 my-auto">            
                                        <div class="h3 my-3">قائمة الفصول</div>
                                    </div>
                                    <div class="col-8 my-auto">
                                        <select dir="rtl" type="text"
                                            name="schoollevel"
                                            [(ngModel)]="filter.schoolLevel"
                                            class="form-select form-select-sm">
                                            <option selected disabled hidden [value]="undefined">المرحلة الدراسية</option>
                                            <option value="">الكل</option>
                                            <option *ngFor="let level of schoolLevels" [value]="level.id">{{level.label}}</option>
                                        </select>
                                    </div>
                                    <div class="col-4">
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-primary w-100"
                                        (click)="search()">
                                        <span *ngIf="!loading">بـحـث</span>
                                        <div *ngIf="loading" class="spinner-border text-light spinner-border-sm">
                                          <span class="sr-only"></span>
                                        </div>
                                      </button>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-8">
                                    </div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-sm btn-success w-100"
                                            (click)="addAll(classList)">
                                            إختيار الكل
                                        </button>
                                    </div>
                                </div>
                                <div class="row" *ngFor="let class of classList">
                                    <div class="col-6 h6">{{ class.name }}</div>
                                    <div class="col-3">
                                        <span class="badge badge-sm bg-primary mx-auto">
                                            {{ class.school_level.name.ar }} / {{ class.year }}
                                        </span>
                                    </div>  
                                    <div class="col-3 h6 text-center">
                                        <button type="button" class="btn btn-sm btn-success"
                                            [disabled]="checkIfAlreadySelected(class.id)"
                                            (click)="resourceData?.classes.push(class)">
                                            + إختيار
                                        </button>
                                    </div>                  
                                </div>
                                <paginator-component
                                    [metadata]="classMetadata"
                                    (getNewPage)="getClasses($event)">
                                </paginator-component>
                                
                                <loader-section
                                    [loading]="loading"
                                    [data]="classList"
                                    [error]="error">
                                </loader-section>
                            </div>
                            <div class="col-6 limited-scroll longer-limit">
                                <div class="row mb-2">
                                    <div class="col-8 my-auto">            
                                        <div class="h3 my-3">الفصول المختارة</div>
                                    </div>
                                    <div class="col-4 my-auto">
                                        <button type="button" class="btn btn-sm btn-danger w-100"
                                            (click)="resourceData.classes = []">
                                            إزالة الكل
                                        </button>
                                    </div>
                                </div>
                                <div class="row" *ngFor="let class of resourceData?.classes; index as i">
                                    <div class="col-6 h6">{{ class.name }}</div>
                                    <div class="col-3">
                                        <span class="badge badge-sm bg-primary mx-auto">
                                            {{ class.school_level.name.ar }} / {{ class.year }}
                                        </span>
                                    </div>  
                                    <div class="col-3 h6">
                                        <button type="button"
                                            class="btn btn-sm btn-danger"
                                            (click)="resourceData?.classes.splice(i, 1)">
                                            - إزالة
                                        </button>
                                    </div>                              
                                </div>
                                <loader-section
                                    [loading]="loading"
                                    [data]="resourceData?.classes"
                                    [error]="error">
                                </loader-section>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row py-3">
                    <div class="row w-100">
                        <div class="col-md mx-auto" [class.d-none]="selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading"
                                class="btn btn-primary text-light w-100">
                                {{ loading ? '' : 'إضافة' }}

                                <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                        
                        <div class="col-md mx-auto" [class.d-none]="!selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading"
                                class="btn btn-primary text-light w-100">
                                {{ loading ? '' : 'تعديل' }}
                                
                                <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>

                        <div class="col-md mx-auto">
                            <button type="button"
                                class="btn btn-danger text-light w-100"
                                (click)="closeAction.emit(true)">إلغاء
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>