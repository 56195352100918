import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Class, SchoolLevels } from 'src/app/interfaces/class.interface';
import { Parent } from 'src/app/interfaces/parent.interface';
import { DefaultStudent, Student } from 'src/app/interfaces/student.interface';
import { ClassService } from 'src/app/services/resources/class.service';
import { ParentService } from 'src/app/services/resources/parent.service';
import { StudentService } from 'src/app/services/resources/student.service';

@Component({
  selector: 'app-student-form-modal',
  templateUrl: './student-form-modal.component.html',
  styleUrls: ['./student-form-modal.component.scss']
})
export class StudentFormModalComponent implements OnInit {

  @Input() selectedParentId: any = null;
  @Output() closeAction = new EventEmitter<boolean>(); 

  selectedStudentId: any;

  loading: boolean;
  error: boolean;
  fileUploading: boolean;

  defaultResourceData: Student = DefaultStudent; 
  resourceData: Student =  JSON.parse(JSON.stringify(this.defaultResourceData));
  parentData: Parent;
  
  schoolLevels: any[] = [
    {id: 1, label: "الإبتدائي"},
    {id: 2, label: "الإعدادي"},
    {id: 3, label: "الثانوي"}
  ];
  
  classList: SchoolLevels[];

  constructor(private studentService: StudentService,
              private classService: ClassService,
              private parentService: ParentService) { }

  ngOnInit(): void {
    if(this.selectedParentId) {
      this.getParentById(this.selectedParentId);
      this.getAllClasses();
      this.resourceData.parent_school_id = this.selectedParentId;
    }
  }

  getParentById(id: any) {
    this.parentService.getSpecific(id, {include: 'students'})
      .pipe(ob => { this.loading = true; return ob }, finalize(() => this.loading = false))
      .subscribe(response => this.parentData = response.data, error => this.error = true)
  }

  getStudentById(id: any) {
    this.studentService.getSpecific(id)
      .pipe(ob => { this.loading = true; return ob }, finalize(() => this.loading = false))
      .subscribe(response => this.resourceData = response.data, error => this.error = true)
  }

  getAllClasses(): void {
    this.classService.getAllByClass()
      .pipe(ob => {this.loading = true; return ob}, finalize(() => this.loading = false))
      .subscribe((response: any) => this.classList = response.data, (error) => this.error = true)
  }

  submitResourceForm(resourceForm: NgForm): void {
    if(this.selectedStudentId) this.submitUpdate(this.selectedStudentId, resourceForm);
    else this.submitNew(resourceForm);
  }

  submitUpdate(id: any, form: NgForm): void {
    this.studentService.patch(id, form.value)
      .pipe(ob => { this.loading = true; return ob }, finalize(() => this.loading = false))
      .subscribe(response => {
        this.getParentById(this.selectedParentId);
        // Resetting like this since we want specific default values, not nulls
        this.resourceData = JSON.parse(JSON.stringify(this.defaultResourceData));
      }, error => this.error = true)
  }

  submitNew(form: NgForm): void {
    form.value.parent_school_id = this.selectedParentId;
    this.studentService.post(form.value)
      .pipe(ob => { this.loading = true; return ob }, finalize(() => this.loading = false))
      .subscribe(response => {
        this.getParentById(this.selectedParentId);
        // Resetting like this since we want specific default values, not nulls
        this.resourceData = JSON.parse(JSON.stringify(this.defaultResourceData));
      }, error => this.error = true)
  }

  editStudent(student: Student): void {
    this.resourceData = student;
    this.selectedStudentId = student.id;
  }

  clearForm(formData: NgForm): void {
    formData.resetForm();
    this.selectedStudentId = undefined;
  }

  deleteStudent(id): void {
    this.studentService.delete(id)
      .pipe(ob => { this.loading = true; return ob }, finalize(() => this.loading = false))
      .subscribe((response: Response) => this.getParentById(this.selectedParentId), error => this.error = true)
  }

  getKeys(array: any[], attribute: any): string[] {
    let object = (array && attribute) ? array[attribute] : {}; 
    return Object.keys(object);
  }

  get levelYearClassList(): Class[] {
    const valid: boolean = !!(this.classList && this.resourceData?.school_level_id && this.resourceData?.year); 
    const class_array: Class[] = valid ? this.classList[this.resourceData?.school_level_id][this.resourceData?.year] : [];

    return class_array;
  }

}
