import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Class } from 'src/app/interfaces/class.interface';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { DefaultPeriod, Period, Schedule } from 'src/app/interfaces/period.interface';
import { ClassService } from 'src/app/services/resources/class.service';
import { PeriodService } from 'src/app/services/resources/period.service';

@Component({
  selector: 'app-periods-form-modal',
  templateUrl: './periods-form-modal.component.html',
  styleUrls: ['./periods-form-modal.component.scss']
})
export class PeriodsFormModalComponent implements OnInit {

  @Input() selectedResourceId: any = null;
  @Output() closeAction = new EventEmitter<boolean>(); 

  loading: boolean;
  error: boolean;
  fileUploading: boolean;

  defaultResourceData: Period = DefaultPeriod; 
  resourceData: Period =  JSON.parse(JSON.stringify(this.defaultResourceData));

  classList: Class[];
  classMetadata: MetaData;

  schoolLevels: any[] = [
    {id: 1, label: "الإبتدائي"},
    {id: 2, label: "الإعدادي"},
    {id: 3, label: "الثانوي"}
  ];

  filter = {
    schoolLevel: undefined
  }

  timetable: Schedule[] = [
    {day: 'saturday', day_ar: 'السبت', starts_at: undefined, ends_at: undefined, enabled: false},
    {day: 'sunday', day_ar: 'الأحد', starts_at: undefined, ends_at: undefined, enabled: false},
    {day: 'monday', day_ar: 'الإثنين', starts_at: undefined, ends_at: undefined, enabled: false},
    {day: 'tuesday', day_ar: 'الثلثاء', starts_at: undefined, ends_at: undefined, enabled: false},
    {day: 'wedensday', day_ar: 'الإربعاء', starts_at: undefined, ends_at: undefined, enabled: false},
    {day: 'thursday', day_ar: 'الخميس', starts_at: undefined, ends_at: undefined, enabled: false},
    {day: 'friday', day_ar: 'الجمعة', starts_at: undefined, ends_at: undefined, enabled: false},
  ]

  constructor(private resourceService: PeriodService,
              private classService: ClassService) { }

  ngOnInit(): void {
    this.selectedResourceId && this.getResourceById(this.selectedResourceId);
    this.getClasses();
  }

  getResourceById(id: any) {
    this.resourceService.getSpecificWithClasses(id)
      .pipe(ob => {this.loading = true; return ob}, finalize(() => this.loading = false))
      .subscribe(response => {
        this.resourceData = response.data;
        this.timetable.map((row, index) => {
          this.resourceData.timetable.map(apiRow => {
            if(apiRow.day == row.day) {
              row.enabled = true;
              row.starts_at = apiRow.starts_at;
              row.ends_at = apiRow.ends_at;
            }
          })
        })
      }, error => this.error = true)
  }

  submitResourceForm(resourceForm: NgForm): void {
    if(this.selectedResourceId) this.submitUpdate(this.selectedResourceId, resourceForm);
    else this.submitNew(resourceForm);
  }

  addAll(classList: Class[]): void {
    if(!this.resourceData.classes) this.resourceData.classes = [];

    classList.map((specificClass: Class) => {
      if(!this.checkIfAlreadySelected(specificClass.id))
        this.resourceData.classes.push(specificClass);
    })
  }

  getClasses(page: number = 1, query = {}): void {
    this.classService.getAll({page: page, ...query})
      .pipe(ob => {this.loading = true; return ob}, finalize(() => this.loading = false))
      .subscribe(response => {
        this.classList = response.data;
        this.classMetadata = response;
      }, error => this.error = true)
  }

  search() {
    let query = {};
    console.log("this.filter.schoolLevel ", this.filter.schoolLevel);

    if(this.filter.schoolLevel) query['filter[level.id]'] = this.filter.schoolLevel;

    console.log("this.filter.schoolLevel ", this.filter.schoolLevel, query);
    
    this.getClasses(1, query);
  }

  submitUpdate(id: any, form: NgForm): void {
    let timetable: Schedule[] = [];
    this.timetable.map(time => time.enabled && timetable.push(time));
    form.value.timetable = timetable;
    form.value.classes = this.resourceData.classes;
    form.value.class_ids = this.resourceData.classes.map(specificClass => specificClass.id);
    form.value.school_level_ids = this.resourceData.school_level_ids;

    this.resourceService.patch(id, form.value)
      .pipe(ob => {this.loading = true; return ob}, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }

  submitNew(form: NgForm): void {
    let timetable: Schedule[] = [];
    this.timetable.map(time => time.enabled && timetable.push(time));
    form.value.timetable = timetable;
    form.value.classes = this.resourceData.classes;
    form.value.class_ids = this.resourceData.classes.map(specificClass => specificClass.id);
    form.value.school_level_ids = this.resourceData.school_level_ids;

    this.resourceService.post(form.value)
      .pipe(ob => {this.loading = true; return ob}, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }

  getSchoolLevelById(id: number): any {
    return this.schoolLevels.filter(schoolLevel => schoolLevel.id == id)[0];
  }

  checkIfAlreadySelected(id: number): boolean {
    let idList: number[] = this.resourceData?.classes.map(student => student.id);
    return idList.includes(id);
  }

}
