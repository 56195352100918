import { School } from "./school.interface";

export interface SchoolAdmin {
    id?: number,
    name: string,
    email: string,
    password: string,
    status: string,
    password_confirmation?: string,
    school_ids?: number[],
    schools?: School,
    created_at?: string,
    updated_at?: string
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultSchoolAdmin = {
    id: undefined,
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    school_ids: [],
    status: 'active'
}