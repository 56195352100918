import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize, take, takeUntil, tap} from 'rxjs/operators';
import {Alert, DefaultAlert} from 'src/app/interfaces/alert.interface';
import {MetaData} from 'src/app/interfaces/metadata.interface';
import {AlertService} from 'src/app/services/resources/alert.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireDatabase} from '@angular/fire/database';
import {interval, Subject} from 'rxjs';

@Component({
  selector: 'app-alerts-playback-screen',
  templateUrl: './alerts-playback-screen.component.html',
  styleUrls: ['./alerts-playback-screen.component.scss']
})
export class AlertsPlaybackScreenComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  isError: boolean = false;
  pageTitle: string = 'تشغيل النداءات';

  playingAudio: boolean = false;
  resourceList?: Alert[] = [];
  defaultResourceShape: Alert = DefaultAlert;
  metadata: MetaData;

  selectedResourceId: number = undefined;
  selectedResourceData: Alert = undefined;
  defaultStatus: 'online' | 'offline' = 'offline';

  onDestroy$ = new Subject();

  constructor(private resourceService: AlertService,
              private ngbModal: NgbModal,
              private ngfAuth: AngularFireAuth,
              private ngfDB: AngularFireDatabase,
  ) {
  }

  toggleStatus(): void {
    if (this.defaultStatus === 'online') {
      this.defaultStatus = 'offline';
      this.pauseCalls();
    } else {
      this.defaultStatus = 'online';
      this.playCalls();
    }
  }

  setStatus(status: 'online' | 'offline'): void {    
    this.ngfAuth.user
      .pipe(
        take(1),
        tap(user => {
            if (!user) {
              return;
            }
            this.ngfDB.database.ref(`users/${user.uid}`)
              .set({
                status,
                timestamp: Date.now()
              }).then();
          }
        )
      ).subscribe();
  }

  ngOnInit(): void {
    this.getAllCalls();
    
    interval(5000)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => {
          if(this.resourceList?.length == 0 && this.defaultStatus == 'online')
            this.getAllCalls();
        })
      ).subscribe();

    interval(3000)
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => this.setStatus(this.defaultStatus)),
        finalize(() => this.setStatus('offline'))
      ).subscribe();

    this.setStatus(this.defaultStatus);
    window.onunload = () => {
      this?.setStatus?.('offline');
    };
  }


  ngOnDestroy(): void {
    this.onDestroy$.next();
    // this.setStatus('offline');
  }

  getAllCalls(page: number = 1): void {
    this.resourceService.getQueue()
      .pipe(o => {this.isLoading = true; return o;}, finalize(() => {
        this.isLoading = false;
        if (this.playingAudio) {
          setTimeout(() => this.playCalls(), 100);
        }
      }))
      .subscribe((response: any) => {
        this.metadata = response;
        this.resourceList = response.data;
      }, (error) => this.isError = true);
  }

  deleteCall(id: number = this.selectedResourceId): void {
    this.resourceService.deleteCall(id)
      .subscribe(() => this.getAllCalls(), error => this.isError = error);
  }

  playCalls(): void {
    this.playingAudio = true;
    console.log('We are playing the calls!', this.playingAudio);

    let allAudio: HTMLCollectionOf<HTMLAudioElement>
      = document.getElementsByClassName('audio_player') as HTMLCollectionOf<HTMLAudioElement>;

    if (allAudio.item(0) == null) {
      return null;
    }

    allAudio.item(0).currentTime = 0;
    allAudio.item(0).play();
    allAudio.item(0).addEventListener('ended', (event) => this.deleteCall(this.resourceList[0].id));
  }

  pauseCalls(): void {
    this.playingAudio = false;
    console.log('We are Pausing the calls!', this.playingAudio);

    let allAudio: any = document.getElementsByClassName('audio_player');

    if (allAudio.item(0) == null) {
      return null;
    }

    for (var i = 0; i < allAudio.length; i++) {
      allAudio.item(i).currentTime = 0;
      allAudio.item(i).pause();
    }
  }

  getNames(data: any[], nestedStudents): string {
    if (!data?.length) {
      return '';
    }

    let namesString: string = '';
    if (nestedStudents) {
      data.map((obj, index) => index == 0 ? namesString += `${obj.parent_school_student.name} ` : namesString += `- ${obj.parent_school_student.name}`);
    } else {
      data.map((student, index) => index == 0 ? namesString += `${student.name} ` : namesString += `- ${student.name}`);
    }

    return namesString;
  }
}
