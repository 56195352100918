import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ParentService } from 'src/app/services/resources/parent.service';
import * as jsonToFormData from 'json-form-data';
import { UploadExcelService } from 'src/app/services/uploadExcel.service';

@Component({
  selector: 'app-guardian-upload-modal',
  templateUrl: './guardian-upload-modal.component.html',
  styleUrls: ['./guardian-upload-modal.component.scss']
})
export class GuardianUploadModalComponent implements OnInit {

  @Input() selectedResourceId: any = null;
  @Output() closeAction = new EventEmitter<boolean>(); 

  loading: boolean;
  error: boolean;
  
  fileUploading: boolean;
  file: File = undefined;

  constructor(private resourceService: UploadExcelService) { }

  ngOnInit(): void {
  }

  submitResourceForm(resourceForm: NgForm, fileUpload: HTMLInputElement): void {
    this.submitNew(resourceForm, fileUpload);
  }

  submitNew(form: NgForm, fileUpload: HTMLInputElement): void {
    let formData: FormData = jsonToFormData(form.value);

    if(fileUpload.files[0]) formData.set('file', fileUpload.files[0]);

    this.resourceService.post(formData)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }

}
