import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SchoolLevels } from 'src/app/interfaces/class.interface';

@Component({
  selector: 'app-class-table',
  templateUrl: './class-table.component.html',
  styleUrls: ['./class-table.component.scss']
})
export class ClassTableComponent implements OnInit {

  @Input() resourceList: SchoolLevels = undefined;
  @Input() level: string = undefined;
  @Input() year: string = undefined;

  @Output() clickedEdit = new EventEmitter();
  @Output() clickedDelete = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
