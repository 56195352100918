<div class="d-flex hudhud-gradient-background align-items-center justify-content-center">

    <div class="container">
        <div class="row">
            <div class="col-10 col-sm-8 col-lg-4 mx-auto">
                <div class="card hover-shadow shadow">
                    <div class="card-body">

                        <div class="text-center">
                            <img src="assets/img/hudhud_icon-square.png" class="img-fluid w-50"/>
                            <h3 class="text-primary fw-bold"> تسجيل الدخول </h3>
                            <h6 class="text-secondary"> 
                                يرجى كتابة اسم المستخدم وكلمة المرور لتسجيل الدخول
                            </h6>
                        </div>

                        <form #logindata="ngForm" (ngSubmit)="login(logindata)">
                            <div class="mt-5 mb-5">
                                <div class="form-floating mb-4">
                                    <input 
                                        type="email"
                                        class="form-control rounded"
                                        placeholder="البريد الإلكتروني"
                                        name="email"
                                        [(ngModel)]="formData.email"
                                        required 
                                    >
                                    <label for="email">{{ 'البريد الإلكتروني' }}</label>
                                </div>
                                <div class="form-floating mt-4">
                                    <input 
                                        type="password"
                                        class="form-control rounded"
                                        placeholder="الرمز السري"
                                        name="password"
                                        [(ngModel)]="formData.password"
                                        required 
                                    >
                                    <label for="password">{{ 'الرمز السري' }}</label>
                                </div>
                            </div>
                            
                            <div class="row my-4">
                                <div class="col-8 mx-auto">
                                    <button type="submit" class="btn btn-lg btn-primary rounded w-100"
                                        [disabled]="!logindata.valid || loading">
                                        {{ (loading ? 'الرجاء الإنتظار' : 'دخول') }}
                                        <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    