import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {map, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment.prod';
import {Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private JWT_TOKEN = 'JWT_TOKEN';
  private REFRESH_TOKEN = 'REFRESH_TOKEN';
  private ADMIN_DATA = 'ADMIN_DATA';

  constructor(private http: HttpClient, private router: Router, private ngfAuth: AngularFireAuth) {
  }

  postAuth(formData: any): Observable<any> {

    return fromPromise(this.ngfAuth.signInAnonymously())
      .pipe(
        switchMap(auth => fromPromise(auth.user.getIdToken())),
        switchMap(firebaseToken => {
          return this.http.post(`${environment?.AUTH_URL}`, {...formData, firebase_token: firebaseToken })
            .pipe(
              map(reply => {
                localStorage.setItem(this.JWT_TOKEN, reply['accessToken']);
                reply['refresh_token'] && localStorage.setItem(this.REFRESH_TOKEN, reply['refresh_token']);
                reply['admin'] && localStorage.setItem(this.ADMIN_DATA, JSON.stringify(reply['admin']));
                return this.getJwtToken();
              })
            );
        })
      );
  }

  getRefreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  get getAdminData(): string {
    return localStorage.getItem(this.ADMIN_DATA);
  }

  isSuperAdmin(): boolean {
    console.log('checking if superadmin using data: ', JSON.parse(this.getAdminData));
    return (JSON.parse(this.getAdminData)['roles'][0]['name'] == 'superadmin');
  }

  checkForTokenExpiration(): void {
    let logOutHour = Math.floor(Date.now() / 1000);
    let token_exp = localStorage.getItem('TOKEN_EXPIRATION');
    if (logOutHour === parseInt(token_exp) - 60 * 60) {
      this.logout();
    }
  }

  isLoggedIn(): boolean {
    const helper = new JwtHelperService();
    return !!this.getJwtToken && !helper.isTokenExpired(this.getJwtToken());
  }

  removeTokens(): void {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }

  getJwtToken(): string {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  logout(): void {
    this.removeTokens();
    this.router.navigate(['/login']);
  }

  get currentJwtTokenData(): any {
    const helper = new JwtHelperService();
    let token = localStorage.getItem(this.JWT_TOKEN);

    if (!token || helper.isTokenExpired(token)) {
      this.getRefreshToken();
      return null;
    } else {
      return helper.decodeToken(token);
    }
  }
}
