export interface School {
    id?: number,
    name: string,
    created_at?: string,
    updated_at?: string
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultSchool = {
    id: undefined,
    name: ''
}