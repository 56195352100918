<div class="fluid-container">
    <ng-container *ngIf="resourceList && level && year && resourceList[level] && resourceList[level][year]">
        <div class="row mb-2" *ngFor="let resource of resourceList[level][year]">
            <div class="col-3 my-auto text-center">
                <div class="h5">{{ resource.name }}</div>
            </div>
            <div class="col-3 my-auto text-center">
                <div class="h5">الفترة</div>
                <div class="h6">
                    {{resource?.school_diurnal_period ? resource?.school_diurnal_period.name : 'لا يوجد'}}
                </div>
            </div>
            <div class="col-3 my-auto text-center">
                <div class="h6">{{ resource.created_at | date: 'short' }}</div>
            </div>
            <div class="col-3 my-auto text-center">
                <div class="row">
                    <div class="col-6">
                        <div class="btn btn-sm btn-primary w-100"
                            (click)="clickedEdit.emit(resource.id)">
                        تعديل
                    </div>
                    </div>
                    <div class="col-6">
                        <div class="btn btn-sm btn-danger w-100"
                            (click)="clickedDelete.emit(resource.id)">
                            حذف
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
    
        <loader-section
            [loading]="false"
            [data]="resourceList[level][year]"
            [error]="false">
        </loader-section>
    </ng-container>
</div>