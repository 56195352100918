export interface Student {
    id?: number,
    name: string,
    relation: string,
    gender: string,
    notes: string,
    parent_school_id?: number,
    school_class_id?: number,
    class_id?: number,
    school_level_id?: string,
    year?: string
    created_at?: string,
    updated_at?: string
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultStudent: Student = {
    id: undefined,
    name: '',
    relation: '',
    gender: '',
    notes: '',
    school_class_id: undefined,
    school_level_id: undefined,
    year: undefined
}