<div class="container">
    <div class="row">
        <div class="col-11 my-5 mx-auto">
            <div class="h3">{{ pageTitle }}</div>
            <div class="btn btn-lg btn-primary p-3" (click)="openResourceModal(resourceModal, 'add')">
                إضافة مستخدم مدرسة <i class="fas fa-plus mx-2"></i>
            </div>
        </div>
    </div>
    
    <div class="col-11 mx-auto">
        <div class="card overflow-auto p-3">
            <div class="row my-2 mx-2 text-center">
                <div class="col-md-1 col-2 px-0">
                    <span class="h1 text-primary">{{ metadata ? metadata.total : '...' }}</span>
                </div>
                <div style="width: fit-content" class="px-0 h1 text-primary"><b>|</b></div>
                <div class="col-md-1 col-2 px-0 pt-1 text-primary">
                    <div class="h6">إجمالي</div>
                    <div class="h6">مستخدمين</div>
                </div>
            </div>
            <div class="row m-2">
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <input required dir="rtl"
                        type="text"
                        name="search"
                        [(ngModel)]="searchString"
                        placeholder="بـحـث عن المستخدم"
                        class="form-control">
                </div>
                <div class="col-lg-1 col-md-2 col-sm-4">
                    <button class="btn btn-primary w-100"
                            (click)="getAllResources(1, {'filter[name]': searchString})">
                        <span *ngIf="!isLoading">إسم</span>
                        <div *ngIf="isLoading" class="spinner-border text-light spinner-border-sm">
                            <span class="sr-only"></span>
                        </div>
                    </button>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-4">
                    <button class="btn btn-primary w-100"
                            (click)="getAllResources(1, {'filter[school.name]': searchString})">
                        <span *ngIf="!isLoading">إسم المدرسة</span>
                        <div *ngIf="isLoading" class="spinner-border text-light spinner-border-sm">
                            <span class="sr-only"></span>
                        </div>
                    </button>
                </div>
            </div>
            <div class="row m-3">
                <div class="col-12">
                    <div class="row" *ngFor="let resource of resourceList">
                        <div class="col-md-4 col-6 px-0 my-auto text-center">
                            <div class="h5">{{ resource.name }}</div>
                        </div>
                        <div class="col-md-4 col-6 px-0 my-auto text-center" style="direction: ltr;">
                            <div class="h6">{{ resource.email }}</div>
                        </div>
                        <div class="col-md-4 col-12 px-0 my-auto text-center">
                            <div class="row mx-2">
                                <div class="col-6">
                                    <div class="btn btn-primary px-2 w-100" (click)="openResourceModal(resourceModal, 'edit', resource.id)">
                                        تعديل
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="btn btn-danger px-2 w-100" (click)="openResourceModal(deleteModal, 'edit', resource.id)">
                                        حذف
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="my-3">
                    </div>

                    <paginator-component
                        [metadata]="metadata"
                        (getNewPage)="getAllResources($event)">
                    </paginator-component>
                    
                </div>
            </div>

            <loader-section
                [loading]="isLoading"
                [data]="resourceList"
                [error]="isError">
            </loader-section>
            
        </div>
    </div>

    <ng-template #resourceModal let-modal>
        <app-schooladmin-form
            (closeAction)="modal.dismiss('closed')"
            [selectedResourceId]="selectedResourceId">
        </app-schooladmin-form>
    </ng-template>

    <ng-template #deleteModal let-modal>
        <confirm-query-modal
            (confirm)="closeConfirmModal(modal, true)"
            (cancel)="closeConfirmModal(modal, false)"
            (close)="modal.close(e)"
            (dismiss)="modal.dismiss(e)">
        </confirm-query-modal>
    </ng-template>

</div>