import { NgModule } from "@angular/core";
import { SuperAdminComponent } from "./superadmin.component";
import { SchoolMainScreenComponent } from './screens/schools/school-main-screen/school-main-screen.component';
import { SchoolFormComponent } from './screens/schools/school-form/school-form.component';
import { SchooladminMainScreenComponent } from './screens/schooladmins/schooladmin-main-screen/schooladmin-main-screen.component';
import { SchooladminFormComponent } from './screens/schooladmins/schooladmin-form/schooladmin-form.component';
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared.module";

@NgModule({
  declarations: [
    SchoolMainScreenComponent,
    SchoolFormComponent,
    SchooladminMainScreenComponent,
    SchooladminFormComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([
      {path: 'school', component: SchoolMainScreenComponent},
      {path: 'schooladmin', component: SchooladminMainScreenComponent}
    ])
  ],
  providers: [],
  bootstrap: [SuperAdminComponent]
})
export class SuperAdminModule { }
