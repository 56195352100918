import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class ParentService extends CentralService {

  protected resource: string = "parent_school";

  getLatestChats(params: any = undefined): Observable<any> {
    return this.getAll({...params, include:'students', with_latest_inquiry: true});
  }

  uploadExcel(data, params: any = undefined): Observable<any> {
    return this.postSpecific('/upload', data, {...params});
  }
  
}
