<div class="container">
  <div class="row">
    <div class="col-11 my-5 mx-auto">
      <div class="h3">{{ pageTitle }}</div>
      <div class="btn btn-lg btn-primary p-3"
           (click)="openResourceModal(resourceModal, 'add')">
        إضافة فترة <i class="fas fa-plus mx-2"></i>
      </div>
    </div>
  </div>

  <div class="col-11 mx-auto" *ngIf="true">
    <div class="card overflow-auto p-3">
      <div class="row m-2 text-center">
        <div class="col-md-1 col-2 px-0">
          <span class="h1 text-primary">{{ metadata ? metadata.total : '...' }}</span>
        </div>
        <div style="width: fit-content" class="px-0 h1 text-primary"><b>|</b></div>
        <div class="col-md-1 col-2 px-0 pt-1 text-primary">
          <div class="h6">إجمالي</div>
          <div class="h6">الفترات</div>
        </div>
      </div>
      <div class="row m-2">
        <div class="col-lg-4 col-md-6 col-sm-8">
          <input required dir="rtl"
                 type="text"
                 name="search"
                 [(ngModel)]="filter.name"
                 placeholder="بـحـث عن فترة"
                 class="form-control">
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8">
          <select required dir="rtl"
                 type="text"
                 name="schoollevel"
                 [(ngModel)]="filter.schoolLevel"
                 class="form-select">
            <option selected disabled hidden [value]="undefined">المرحلة الدراسية</option>
            <option value="">الكل</option>
            <option *ngFor="let level of schoolLevels" [value]="level.id">{{level.label}}</option>
          </select>
        </div>
        <div class="col-lg-1 col-md-2 col-sm-4">
          <button class="btn btn-primary w-100"
                  (click)="search()">
            <span *ngIf="!isLoading">بـحـث</span>
            <div *ngIf="isLoading" class="spinner-border text-light spinner-border-sm">
              <span class="sr-only"></span>
            </div>
          </button>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-12">
          <div class="row" *ngFor="let resource of resourceList; index as i">
            <div class="col-md-2 col-6 my-auto text-center">
              <div class="h5">{{ i + 1 }}</div>
            </div>
            <div class="col-md-3 col-6 my-auto text-center">
              <div class="h5">{{ resource.name }}</div>
            </div>
            <div class="col-md-3 col-6 my-auto text-center">
              <div class="h6">{{ resource.created_at | date: 'short' }}</div>
            </div>
            <div class="col-md-4 col-12 my-auto text-center">
              <div class="btn btn-sm btn-primary w-75 m-2"
                   (click)="openResourceModal(resourceModal, 'edit', resource.id)">
                تعديل
              </div>
            </div>
            <hr class="my-3">
          </div>

          <paginator-component
            [metadata]="metadata"
            (getNewPage)="getAllResources($event)">
          </paginator-component>

        </div>
      </div>

      <loader-section
        [loading]="isLoading"
        [data]="resourceList"
        [error]="isError">
      </loader-section>

    </div>
  </div>

  <ng-template #resourceModal let-modal>
    <app-periods-form-modal
      (closeAction)="modal.dismiss('closed')"
      [selectedResourceId]="selectedResourceId">
    </app-periods-form-modal>
  </ng-template>

  <ng-template #deleteModal let-modal>
    <confirm-query-modal
      (confirm)="closeConfirmModal(modal, true)"
      (cancel)="closeConfirmModal(modal, false)"
      (close)="modal.close(e)"
      (dismiss)="modal.dismiss(e)">
    </confirm-query-modal>
  </ng-template>

</div>
