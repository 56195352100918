<div class="my-modal-container">
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <button class="btn btn-danger text-light m-1" (click)="dismiss.emit('Cross click')">
            &times;
        </button>
    </div>
    <div class="modal-body">
        <p>{{body}}</p>
    </div>
    <div class="modal-footer text-center">
        <div class="row w-100">
            <div class="col-md-6">
                <button
                    type="button"
                    class="btn btn-primary text-light w-100"
                    (click)="confirm.emit()">موافق</button>
            </div>
            <div class="col-md-6">
                <button
                    type="button"
                    class="btn btn-danger text-light w-100"
                    (click)="cancel.emit()">إلغاء</button>
            </div>
        </div>
    </div>
</div>