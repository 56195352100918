import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CentralService {

  protected resource: string = "";
  // in this system, the admin prefixes are either superadmin or schooladmin
  protected admin_prefix: string = "schooladmin";

  get fullURL(): string {
    return  `${environment.API_URL}/${this.admin_prefix}/${this.resource}`;
  }

  constructor(public http: HttpClient) {
  }

  getAll(params?: any): Observable<any> {
    return (params) ? this.http.get(this.fullURL, {params}) : this.http.get(this.fullURL);
  }

  getSpecific(id: string | number, params?: any): Observable<any> {
    return this.http.get(this.fullURL + '/' + id, {params});
  }

  patch(id: string | number, data: any, params?: any): Observable<any> {
    return this.http.patch(this.fullURL + '/' + id, data, {params});
  }

  patchByPost(id: string | number, data: any, params?: any): Observable<any> {
    let parameters = {
      params: {
        '_method': "PATCH",
        ...params
      }
    };
    return this.http.post(this.fullURL + '/' + id, data, parameters);
  }

  delete(id: string | number, params?: any): Observable<any> {
    return this.http.delete(this.fullURL + '/' + id, {params});
  }

  post(data: any, params?: any): Observable<any> {
    return this.http.post(this.fullURL, data, {params})
  }

  postSpecific(id: string | number, data: any, params?: any): Observable<any> {
    return this.http.post(this.fullURL + '/' + id, data, {params})
  }

}
