<div class="container">
    <div class="row">
        <div class="col-11 my-5 mx-auto">
            <div class="h3">{{ pageTitle }}</div>
        </div>
    </div>

    <div class="col-11 mx-auto" *ngIf="true">
        <div class="card overflow-auto p-3">
            <div class="row my-2 mx-2 text-center">
                <div class="col-md-1 col-2 px-0">
                    <span class="h1 text-primary">{{ metadata ? metadata.total : '...' }}</span>
                </div>
                <div style="width: fit-content" class="px-0 h1 text-primary"><b>|</b></div>
                <div class="col-md-1 col-2 px-0 pt-1 text-primary">
                    <div class="h6">إجمالي</div>
                    <div class="h6">النداءات</div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-3">
                    <ng-select [items]="classRooms" required
                        bindLabel="name"
                        bindValue="id"
                        placeholder="الصف الدراسي"
                        name="selectedClassroom"
                        [(ngModel)]="selectedClassroom">
                    </ng-select>
                </div>
                <div class="col-2">
                    <ng-select [items]="genders" required
                        bindLabel="label"
                        bindValue="title"
                        placeholder="الجنس"
                        name="selectedGender"
                        [(ngModel)]="selectedGender">
                    </ng-select>
                </div>
                <div class="col-4"></div>
                <div class="col-3">
                    <input type="text"
                        class="form-control"
                        placeholder="بحث عن طالب أو ولي أمر"
                        (change)="search($event)">
                </div>
            </div> -->
            <div class="row m-3">
                <div class="col-md-2 col-6 p-1">
                    <div [class]="'btn btn-sm w-100 ' +
                         (selectedSorting == 'all' ? 'btn-primary' : 'btn-outline-primary')"
                         (click)="getAllResources('all')">الكل</div>
                </div>
                <div class="col-md-2 col-6 p-1">
                    <div [class]="'btn btn-sm w-100 ' +
                         (selectedSorting == 'pending' ? 'btn-primary' : 'btn-outline-primary')"
                         (click)="getAllResources('pending')">قيد الإنتظار</div>
                </div>
                <div class="col-md-2 col-6 p-1">
                    <div [class]="'btn btn-sm w-100 ' +
                         (selectedSorting == 'approved' ? 'btn-primary' : 'btn-outline-primary')"
                         (click)="getAllResources('approved')">تمت الموافقة</div>
                </div>
                <div class="col-md-2 col-6 p-1">
                    <div [class]="'btn btn-sm w-100 ' +
                         (selectedSorting == 'declined' ? 'btn-primary' : 'btn-outline-primary')"
                         (click)="getAllResources('declined')">تم الرفض</div>
                </div>
            </div>
            <div class="row m-3">
                <div class="col-12">
                    <div class="row" *ngFor="let resource of resourceList">
                        <div class="col-lg-3 col-md-4 col-6 p-1 text-center">
                            <div class="h5 mb-0 pb-0">{{ resource.parent.name }}</div>
                            <span class="badge bg-primary" *ngFor="let student of resource.parent.students">{{ student.name }}</span>
                        </div>
                        <div class="col-lg-2 col-md-3 col-5 py-1 text-center">
                            <span class="badge bg-primary" *ngIf="resource.status == 'pending'">
                                قيد الإنتظار
                            </span>
                            <span class="badge bg-success" *ngIf="resource.status == 'approved'">
                                تمت الموافقة
                            </span>
                            <span class="badge bg-danger" *ngIf="resource.status == 'declined'">
                                تم الرفض
                            </span>
                            <div class="h6">{{resource.updated_at | date: 'short'}}</div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-5 py-1 text-center">
                            <div class="h6 mb-0 pb-0">{{ 'النداء من أجل' }}</div>
                            <span class="badge bg-primary">{{ getNames(resource.student_call, true) }}</span>
                        </div>
                        <div class="col-lg-3 col-md-6 d-flex justify-content-center align-items-center">
                            <span style="transform: scale(0.85);">
                                <audio #audio controls> <source [src]="resource.call_url"> </audio>
                            </span>
                        </div>
                        <div class="col-lg-2 col-md-6 d-flex justify-content-center align-items-center">                            
                            <div class="btn-group m-1" role="group" style="border-radius: 10px; overflow: hidden;">
                                <button type="button"
                                    class="btn btn-success"
                                    title="موافقة"
                                    (click)="openResourceModal(approveModal, 'sm', resource.id)">
                                    ✓
                                </button>
                                <button type="button"
                                    class="btn btn-primary"
                                    title="قيد الانتظار"
                                    (click)="openResourceModal(pendingModal, 'sm', resource.id)">
                                    <i class="fas fa-clock"></i>
                                </button>
                                <button type="button"
                                    class="btn btn-danger"
                                    title="رفض"
                                    (click)="openResourceModal(declineModal, 'sm', resource.id)">
                                    ✗
                                </button>
                            </div>
                        </div>
                        <hr class=" my-3">
                    </div>

                    <paginator-component
                        [metadata]="metadata"
                        (getNewPage)="getAllChats(this.selectedSorting, $event)">
                    </paginator-component>
                </div>
            </div>

            <loader-section
                [loading]="isLoading"
                [data]="resourceList"
                [error]="isError">
            </loader-section>

        </div>
    </div>

    <ng-template #pendingModal let-modal>
        <confirm-query-modal
            [title]="'قيد الإنتظار'"
            [body]="'هل تريد النداء قيد الإنتظار؟'"
            (confirm)="closeCallbackModal(modal, true, this.pendingCall())"
            (cancel)="modal.close(e)"
            (close)="modal.close(e)"
            (dismiss)="modal.dismiss(e)">
        </confirm-query-modal>
    </ng-template>

    <ng-template #approveModal let-modal>
        <confirm-query-modal
            [title]="'تأكيد النداء'"
            [body]="'هل تريد تأكيد النداء؟'"
            (confirm)="closeCallbackModal(modal, true, this.approveCall())"
            (cancel)="modal.close(e)"
            (close)="modal.close(e)"
            (dismiss)="modal.dismiss(e)">
        </confirm-query-modal>
    </ng-template>

    <ng-template #declineModal let-modal>
        <confirm-query-modal
            [title]="'رفض النداء'"
            [body]="'هل تريد رفض النداء؟'"
            (confirm)="closeCallbackModal(modal, true, this.declineCall())"
            (cancel)="modal.close(e)"
            (close)="modal.close(e)"
            (dismiss)="modal.dismiss(e)">
        </confirm-query-modal>
    </ng-template>

    <ng-template #deleteModal let-modal>
        <confirm-query-modal
            (confirm)="closeCallbackModal(modal, true, this.deleteCall())"
            (cancel)="modal.close(e)"
            (close)="modal.close(e)"
            (dismiss)="modal.dismiss(e)">
        </confirm-query-modal>
    </ng-template>

</div>
