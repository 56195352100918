<div class="fluid-container p-3">
    <div class="row">
        <div class="col-11 mx-auto">
            <h3>المدرسة</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-11 mx-auto">
            <form #resourceForm="ngForm" (submit)="submitResourceForm(resourceForm)">
                <div class="row">
                    <div class="col-md-6 col-12 my-3">
                        <input required dir="rtl"
                            type="text"
                            name="name"
                            [(ngModel)]="resourceData.name"
                            placeholder="الإسم"
                            class="form-control">
                    </div>       
                    <input type="text" name="status" hidden [(ngModel)]="resourceData.status">         
                    <div class="col-md-6 col-12 my-3">
                        <input required dir="rtl"
                            type="text"
                            name="description"
                            [(ngModel)]="resourceData.description"
                            placeholder="الوصف"
                            class="form-control">
                    </div>
                </div>

                <div class="row">
                    <div class="col-6 limited-scroll longer-limit">
                        <div class="row">
                            <div class="col-12 my-auto">            
                                <div class="h3 my-3">قائمة المستخدمين</div>
                            </div>
                        </div>
                        <div class="row text-center" *ngFor="let admin of schoolAdminList">
                            <div class="col-4 h6">{{ admin.id }}</div>
                            <div class="col-4 h6">{{ admin.name }}</div>
                            <div class="col-4 h6">
                                <button type="button" class="btn btn-sm btn-success w-100"
                                    [disabled]="selectedSchoolAdminsIncludes(admin.id)"
                                    (click)="selectedSchoolAdmins.push(admin)">
                                    أختيار
                                </button>
                            </div>                  
                        </div>
                        <paginator-component
                            [metadata]="adminMetadata"
                            (getNewPage)="getSchoolAdmins($event)">
                        </paginator-component>
                        
                        <loader-section
                            [loading]="adminLoading"
                            [data]="schoolAdminList"
                            [error]="adminError">
                        </loader-section>
                    </div>
                    <div class="col-6 limited-scroll longer-limit">
                        <div class="row">
                            <div class="col-12 my-auto">            
                                <div class="h3 my-3">المستخدمين المختارة</div>
                            </div>
                        </div>
                        <div class="row text-center" *ngFor="let admin of selectedSchoolAdmins; index as i">
                            <div class="col-4 h6">{{ admin.id }}</div> 
                            <div class="col-4 h6">{{ admin.name }}</div>
                            <div class="col-4 h6">
                                <button type="button"
                                    class="btn btn-sm btn-danger w-100"
                                    (click)="selectedSchoolAdmins.splice(i, 1)">
                                إزالة
                                </button>
                            </div>                              
                        </div>
                        <loader-section
                            [loading]="adminLoading"
                            [data]="selectedSchoolAdmins"
                            [error]="adminError">
                        </loader-section>
                    </div>
                </div>
                
                <div class="row py-3">
                    <div class="row w-100">
                        <div class="col-md mx-auto" [class.d-none]="selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading"
                                class="btn btn-primary text-light w-100">
                                {{ loading ? '' : 'إضافة' }}

                                <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>

                            </button>
                        </div>
                        
                        <div class="col-md mx-auto" [class.d-none]="!selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading"
                                class="btn btn-primary text-light w-100">
                                {{ loading ? '' : 'تعديل' }}
                                
                                <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>

                            </button>
                        </div>
                        <div class="col-md mx-auto">
                            <button type="button"
                                class="btn btn-danger text-light w-100"
                                (click)="closeAction.emit(true)">إلغاء</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>