import { Student } from "./student.interface";

export interface Notification {
    id?: number,
    type: string,
    body: string,
    image?: any,
    parent_school_student?: Student[],
    status: string,
    school_id?: number,
    image_id?: number,
    created_at?: string,
    updated_at?: string
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultNotification = {
    id: undefined,
    status: 'public',
    type: '',
    body: ''
}