import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { DefaultNotification, Notification } from 'src/app/interfaces/notification.interface';
import { Student } from 'src/app/interfaces/student.interface';
import { NotificationService } from 'src/app/services/resources/notification.service';
import { StudentService } from 'src/app/services/resources/student.service';
import * as jsonToFormData from 'json-form-data';
import { Class } from 'src/app/interfaces/class.interface';

@Component({
  selector: 'app-notification-form-modal',
  templateUrl: './notification-form-modal.component.html',
  styleUrls: ['./notification-form-modal.component.scss']
})
export class NotificationFormModalComponent implements OnInit {

  @Input() selectedResourceId: any = null;
  @Input() copyNotification: any = null;
  @Input() classList: Class[] = null;
  @Output() closeAction = new EventEmitter<boolean>(); 

  loading: boolean;
  error: any;

  fileUploading: boolean;
  uploaderImage: string;
  uploaderFileList: FileList;

  studentsList: Student[];
  studentsMetadata: MetaData;
  chosenStudentsList: Student[] = [];
  
  searchForm: any = {
    'filter[gender]': '',
    'filter[name]': '',
    'filter[class.year]': '',
    'filter[class.school_level_id]': '',
    'filter[class.id]': ''
  }

  schoolLevels: any[] = [
    {id: 1, label: "الإبتدائي"},
    {id: 2, label: "الإعدادي"},
    {id: 3, label: "الثانوي"}
  ];

  defaultResourceData: Notification = DefaultNotification; 
  resourceData: Notification =  JSON.parse(JSON.stringify(this.defaultResourceData));

  constructor(private resourceService: NotificationService, 
              private studentService: StudentService) { }

  ngOnInit(): void {
    if(this.selectedResourceId) this.getResourceById(this.selectedResourceId);
    this.getStudents();
  }

  getResourceById(id: any): void {
    this.resourceService.getSpecific(id, { include: 'students' })
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => {
        this.resourceData = response.data;
        this.chosenStudentsList = [...this.resourceData.parent_school_student];
      }, e => this.error = e)
  }

  getStudents(page: number = 1): void {
    let data = {};
    Object.keys(this.searchForm).map(key => this.searchForm[key] ? data[key] = this.searchForm[key] : undefined );
    
    this.studentService.getAll({page, ...data})
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => {
        this.studentsList = response.data;
        this.studentsMetadata = response;
      }, e => this.error = e)
  }

  addSelectedStudents(studentsList: Student[]): void {
    studentsList.map((student: Student) => {
      if(!this.checkIfAlreadySelected(student.id))
        this.chosenStudentsList.push(student);
    })
  }

  submitResourceForm(resourceForm: NgForm): void {
    console.log("fileUpload ", this.uploaderFileList);
    console.log("formdata and json ", resourceForm.value, this.resourceData);
    if(this.selectedResourceId && !this.copyNotification) this.submitUpdate(this.selectedResourceId, this.resourceData);
    else this.submitNew(this.resourceData);
  }

  submitUpdate(id: any, data: Notification): void {
    data['student_ids'] = this.chosenStudentsList.map(student => student.id);
    let formData: FormData = jsonToFormData(data);
    if(this.uploaderFileList && this.uploaderFileList[0]) formData.set('image', this.uploaderFileList[0]);

    this.resourceService.patch(id, formData)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), e => this.error = e)
  }

  submitNew(data: Notification): void {
    data['student_ids'] = this.chosenStudentsList.map(student => student.id);
    let formData: FormData = jsonToFormData(data);
    if(this.uploaderFileList && this.uploaderFileList[0]) formData.set('image', this.uploaderFileList[0]);

    this.resourceService.post(formData)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), e => this.error = e)
  }

  checkIfAlreadySelected(id: number): boolean {
    let idList: number[] = this.chosenStudentsList.map(student => student.id);
    return idList.includes(id);
  }

  getKeys(object: any): string[] {
    return Object.keys(object);
  }

  get levelYearClassList(): Class[] {
    const valid: boolean = !!(this.classList && this.searchForm['filter[class.school_level_id]'] && this.searchForm['filter[class.year]']); 
    const class_array: Class[] = valid ? this.classList[this.searchForm['filter[class.school_level_id]']][this.searchForm['filter[class.year]']] : [];

    return class_array;
  }

}
