import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loader-section',
  templateUrl: './loader-section.component.html',
  styleUrls: ['./loader-section.component.scss']
})
export class LoaderSectionComponent implements OnInit {

  @Input() loading: boolean;
  @Input() error: boolean;
  @Input() data: any;
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
