import { SchoolAdmin } from "./schooladmin.interface";

export interface School {
    id?: number,
    name: string,
    description: string,
    status: string,
    school_admins?: SchoolAdmin[],
    school_admin_ids?: number[],
    created_at?: string,
    updated_at?: string
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultSchool = {
    id: undefined,
    name: '',
    description: '',
    school_admin_ids: [],
    status: 'active'
}