<div class="fluid-container p-3">
    <div class="row">
        <div class="col-11 mx-auto">
            <h3>الأخبار</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-11 mx-auto">
            <form #resourceForm="ngForm" (submit)="submitResourceForm(resourceForm)">
                <div class="row">
                    <div class="col-6 my-3">
                        <div class="row">
                            <div class="col-8">
                                <input required
                                    type="text"
                                    name="title"
                                    [(ngModel)]="resourceData.title"
                                    placeholder="العنوان"
                                    dir="rtl"
                                    class="form-control">
                            </div>
                            <div class="col-4">
                                <input required
                                    type="text"
                                    name="code"
                                    [(ngModel)]="resourceData.code"
                                    placeholder="كود الخبر"
                                    dir="rtl"
                                    class="form-control">
                            </div>
                            <div class="col-12 my-3">
                                <input type="text" name="status" style="display: none;"
                                    [(ngModel)]="resourceData.status">
                                <div class="btn-group m-1 w-100 rounded" role="group" style="overflow: hidden">
                                    <div [class]="'btn ' + (resourceData.status == 'active' ? 'btn-success' : 'btn-secondary')"
                                        (click)="resourceData.status = 'active'">مفعل
                                    </div>
                                    <div [class]="'btn ' + (resourceData.status == 'disabled' ? 'btn-danger' : 'btn-secondary')"
                                        (click)="resourceData.status = 'disabled'">غير مفعل
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 my-3">
                        <moud-uploader
                            [required]="!selectedResourceId"
                            [loading]="loading"
                            [(inputValue)]="resourceData.image_url"
                            [backgroundImage]="resourceData.image_url ? 'url('+resourceData.image_url+')' : undefined"
                            [(inputFileList)]="uploaderFileList">
                        </moud-uploader>
                    </div>
                    <div class="col-12 my-3">
                        <div class="NgxEditor__Wrapper mx-auto" *ngIf="!!'angularBugFix'">
                            <ngx-editor-menu [toolbar]="toolbar" [editor]="editor">
                            </ngx-editor-menu>
                            <ngx-editor required name="body"
                                [editor]="editor"
                                [(ngModel)]="resourceData.body"
                                [editable]="true"
                                [placeholder]="'أكتب هنا...'" >
                            </ngx-editor>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="row w-100">
                        <div class="col-md mx-auto" [class.d-none]="selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading || fileUploading"
                                class="btn btn-primary text-light w-100">
                                {{ loading || fileUploading ? '' : 'إضافة' }}

                                <div *ngIf="loading || fileUploading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>
                                {{ fileUploading ? 'بإنتظار رفع الملف' : '' }}

                            </button>
                        </div>
                        <div class="col-md mx-auto" [class.d-none]="!selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading || fileUploading"
                                class="btn btn-primary text-light w-100">
                                {{ loading || fileUploading ? '' : 'تعديل' }}

                                <div *ngIf="loading || fileUploading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>
                                {{ fileUploading ? 'بإنتظار رفع الملف' : '' }}

                            </button>
                        </div>
                        <div class="col-md mx-auto">
                            <button type="button"
                                class="btn btn-danger text-light w-100"
                                (click)="closeAction.emit(true)">إلغاء</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
