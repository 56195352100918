import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class ClassService extends CentralService {

  protected resource: string = "school_class";

  getAllByClass(params: any = undefined): Observable<any> {
    return this.getAll({...params, groupByYear: 1})
  }

}
