export interface Alert {
    id?: number,
    parentName: string,
    studentName: string,
    date: number,
    status: string,
    audio_url?: string,
    audio_length?: number,
    created_at?: string,
    updated_at?: string
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultAlert = {
    id: undefined,
    parentName: '',
    studentName: '',
    date: undefined,
    status: ''
}