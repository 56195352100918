import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { DefaultParent, Parent } from 'src/app/interfaces/parent.interface';
import { Student } from 'src/app/interfaces/student.interface';
import { ParentService } from 'src/app/services/resources/parent.service';

@Component({
  selector: 'app-student-guardian-main-screen',
  templateUrl: './student-guardian-main-screen.component.html',
  styleUrls: ['./student-guardian-main-screen.component.scss']
})
export class StudentGuardianMainScreenComponent implements OnInit {

  isLoading: boolean = false;
  isError: boolean = false;
  pageTitle: string = 'إدارة أولياء الأمور والطلبة';

  resourceList: Parent[] = undefined;
  defaultResourceShape: Parent = DefaultParent;

  metadata: MetaData;
  searchString: string;
  
  selectedResourceId: number = undefined;

  constructor(private resourceService: ParentService,
              private modal: NgbModal) { }


  ngOnInit(): void {
    this.getAllResources();
  }

  getAllResources(page: number = 1, filter: any = {}): void {
    this.resourceService.getAll({page: page, include:'students', ...filter})
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: any) => {
        this.resourceList = response.data;
        this.metadata = response;
      }, (error) => this.isError = true)
  }

  openResourceModal(content: HTMLElement, type?: string, id: any = undefined): void {
    if(id) this.selectedResourceId = id;
    
    this.modal.open(content, { size: ((type == "delete") ? undefined : 'xl'), centered: true,
      beforeDismiss: () => {
        this.selectedResourceId = undefined;
        this.getAllResources();
        return true;
      } })
  }
  
  cloneObject(item: any): any {
    return JSON.parse(JSON.stringify(item));
  }

  closeConfirmModal(modal:NgbActiveModal, confirmed:boolean): void {
    if(confirmed) this.deleteResource();
    modal.close(confirmed);
  }

  deleteResource(): void {
    this.resourceService.delete(this.selectedResourceId)
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: Response) => {
        this.resourceList = response['data'];
        this.selectedResourceId = undefined;
        this.getAllResources();
      }, error => {
        this.isError = error;
        console.warn(error);
      })
  }

  getNames(students: Student[]): string {
    if(!students?.length) return '';
    
    let namesString: string = '';
    
    students.map((student, index) => index == 0 ? namesString += `${student.name} ` : namesString += `- ${student.name}`);
    
    return namesString;
  }
}
