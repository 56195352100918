import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'confirm-query-modal',
  templateUrl: './confirm-query-modal.component.html',
  styleUrls: ['./confirm-query-modal.component.scss']
})
export class ConfirmQueryModalComponent implements OnInit {

  @Input() title: string = 'تأكيد الحذف';
  @Input() body: string = 'هل أنت متأكد أنك تريد حذف؟';
  
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() dismiss = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
