<div class="container">
    <div class="row">
        <div class="col-11 my-5 mx-auto">
            <div class="h3">{{ pageTitle }}</div>
            <button class="btn btn-lg btn-primary p-3" [disabled]="!classList" (click)="openResourceModal(resourceModal, 'add')">
                إرسال إشعار <i class="fas fa-plus mx-2"></i>
            </button>
        </div>
    </div>

    <div class="col-11 mx-auto" *ngIf="true">
        <div class="card overflow-auto p-3">
            <div class="row m-2 text-center">
                <div class="col-md-1 col-2 px-0">
                    <span class="h1 text-primary">{{ metadata ? metadata.total : '...' }}</span>
                </div>
                <div style="width: fit-content" class="px-0 h1 text-primary"><b>|</b></div>
                <div class="col-md-1 col-2 px-0 pt-1 text-primary">
                    <div class="h6">إجمالي</div>
                    <div class="h6">الإشعارات</div>
                </div>
            </div>
            <div class="row m-2">
                <div class="col-lg-3 col-md-5 col-sm-6">
                    <input required dir="rtl"
                        type="text"
                        name="search"
                        [(ngModel)]="searchString"
                        placeholder="بـحـث في الإشعارات"
                        class="form-control">
                </div>
                <div class="col-lg-1 col-md-2 col-sm-3">
                    <button class="btn btn-primary w-100"
                            (click)="getAllResources(1, {'filter[type]': searchString || undefined})">
                        <span *ngIf="!isLoading">النوع</span>
                        <div *ngIf="isLoading" class="spinner-border text-light spinner-border-sm">
                            <span class="sr-only"></span>
                        </div>
                    </button>
                </div>
                <div class="col-lg-1 col-md-2 col-sm-3">
                    <button class="btn btn-primary w-100"
                            (click)="getAllResources(1, {'filter[body]': searchString || undefined})">
                        <span *ngIf="!isLoading">النص</span>
                        <div *ngIf="isLoading" class="spinner-border text-light spinner-border-sm">
                            <span class="sr-only"></span>
                        </div>
                    </button>
                </div>

                <ng-container *ngIf="!classList">
                    <div class="spinner-border text-primary spinner-border-lg p-1 mx-4" role="status">
                        <span class="sr-only"></span>
                    </div>
                </ng-container>  
                <ng-container *ngIf="classList">                    
                    <div class="col-lg-3 col-md-2 col-sm-3">
                        <select required dir="rtl"
                                type="text"
                                [(ngModel)]="filter.school_level_id"
                                (change)="getAllResources(1, filter.school_level_id != 'undefined' ? {'filter[school_level_id]': filter.school_level_id} : {})"
                                name="schoollevel"
                                class="form-select">
                            <option selected disabled hidden [value]="undefined">المرحلة الدراسية</option>
                            <option [value]="undefined">الكل</option>
                            <option *ngFor="let level of schoolLevels" [value]="level.id">{{level.label}}</option>
                        </select>
                    </div>

                    <div class="col-lg-2 col-md-2 col-sm-3" *ngIf="filter?.school_level_id != 'undefined'"> 
                        <select required dir="rtl" #yearSelect
                                type="text"
                                [(ngModel)]="filter.year"
                                name="year"
                                class="form-select">
                            <option selected disabled hidden [value]="undefined">العام</option>
                            <option [value]="undefined">الكل</option>
                            <option *ngFor="let year of getKeys(classList[filter.school_level_id])" [value]="year">{{year}}</option>
                        </select>
                    </div>
                    
                    <div class="col-lg-2 col-md-2 col-sm-3" *ngIf="filter.school_level_id !='undefined' && filter.year !='undefined'">
                        <select required dir="rtl"
                                type="text"
                                name="class"
                                [(ngModel)]="filter.class_id"
                                (change)="getAllResources(1, filter.class_id != 'undefined' ? {'filter[class_id]': filter.class_id} : {})"
                                class="form-select">
                            <option selected disabled hidden [value]="undefined">الفصل</option>
                            <option [value]="undefined">الكل</option>
                            <option *ngFor="let class of classList[filter.school_level_id][filter.year]" [value]="class.id">{{class.name}}</option>
                        </select>
                    </div>
                </ng-container>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <div class="row m-4" *ngFor="let resource of resourceList">
                        <div class="col-1 px-0 my-auto text-center">
                            <div class="h1">
                                <i class="fas fa-bell text-primary"></i>
                            </div>
                        </div>
                        <div class="col-3 px-2 my-auto">
                            <div class="h5">{{ resource.type }}</div>
                            <div class="hudhud-font-small">
                                {{(resource.body | slice:0:45) + (resource?.body?.length > 45 ? '...' : '')}}
                            </div>
                        </div>
                        <div class="col-3 px-0 my-auto text-center">
                            <span class="badge bg-success" *ngIf="resource.status == 'public'">
                                إرسال فوري
                            </span>
                            <span class="badge bg-primary" *ngIf="resource.status == 'draft'">
                                مسودة
                            </span>
                            <div class="h6">{{ resource.created_at | date: 'short' }}</div>
                        </div>
                        <div class="col-5 px-0 my-auto text-center">
                            <div class="row mx-2">
                                <div [class]="resource.status == 'draft' ? 'col-6' : 'col-12'">
                                    <button class="btn btn-sm btn-outline-primary px-1 w-100"
                                        (click)="openResourceModal(resourceModal, 'copy', resource.id)">
                                        إرسال نفس الإشعار
                                    </button>
                                </div>
                                <div class="col-3" *ngIf="resource.status == 'draft'">
                                    <button class="btn btn-sm btn-outline-primary px-1 w-100"
                                        (click)="openResourceModal(resourceModal, 'edit', resource.id)">
                                        تعديل
                                    </button>
                                </div>
                                <div class="col-3" *ngIf="resource.status == 'draft'">
                                    <button class="btn btn-sm btn-danger px-1 w-100"
                                        (click)="openResourceModal(deleteModal, 'delete', resource.id)">
                                        حذف
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <paginator-component
                        [metadata]="metadata"
                        (getNewPage)="getAllResources($event)">
                    </paginator-component>

                </div>
            </div>

            <loader-section
                [loading]="isLoading"
                [data]="resourceList"
                [error]="isError">
            </loader-section>

        </div>
    </div>

    <ng-template #resourceModal let-modal>
        <app-notification-form-modal
            (closeAction)="modal.dismiss('closed')"
            [copyNotification]="copyNotification"
            [classList]="classList"
            [selectedResourceId]="selectedResourceId">
        </app-notification-form-modal>
    </ng-template>

    <ng-template #deleteModal let-modal>
        <confirm-query-modal
            (confirm)="closeConfirmModal(modal, true)"
            (cancel)="closeConfirmModal(modal, false)"
            (close)="modal.dismiss(e)"
            (dismiss)="modal.dismiss(e)">
        </confirm-query-modal>
    </ng-template>

</div>
