import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class NotificationService extends CentralService {

  protected resource: string = "school_announcement";  

}
