import { Class } from "./class.interface";

export interface Period {
    id?: number,
    name: string,
    classes?: Class[],
    timetable: Schedule[],
    school_level_ids: number[],
    created_at?: string,
    updated_at?: string
}

export interface Schedule {
    day: string,
    starts_at: string,
    ends_at: string,
    day_ar?: string,
    enabled?: boolean
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultPeriod = {
    id: undefined,
    name: '',
    timetable: [],
    classes: [],
    school_level_ids: []
}