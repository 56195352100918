
<nav aria-label="Page navigation" *ngIf="metadata && metadata.total > 0">
    <ul class="pagination">
        <li class="page-item" [class.disabled]="metadata?.current_page == 1">
            <a class="page-link pointer" (click)="getNewPage.emit(metadata?.current_page-1)">
                السابق
            </a>
        </li>

        <li class="page-item" *ngFor="let page of buildArray(metadata?.last_page)">
            <a class="page-link pointer" (click)="getNewPage.emit(page + 1)">
                {{ page + 1 }}
            </a>
        </li>

        <li class="page-item" [class.disabled]="metadata?.current_page == metadata?.last_page">
            <a class="page-link pointer" (click)="getNewPage.emit(metadata?.current_page+1)">
                التالي
            </a>
        </li>
    </ul>
</nav>