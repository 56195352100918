import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { DefaultSchool, School } from 'src/app/interfaces/school.interface';
import { SchoolService } from 'src/app/superadmin/services/school.service';

@Component({
  selector: 'app-school-main-screen',
  templateUrl: './school-main-screen.component.html',
  styleUrls: ['./school-main-screen.component.scss']
})
export class SchoolMainScreenComponent implements OnInit {

  isLoading: boolean = false;
  isError: boolean = false;
  pageTitle: string = 'إدارة المدارس';

  resourceList: School[] = undefined;
  defaultResourceShape: School = DefaultSchool;

  metadata: MetaData;
  searchString: string;

  selectedResourceId: number = undefined;

  constructor(private resourceService: SchoolService, private modal: NgbModal) { }


  ngOnInit(): void {
    this.getAllResources();
  }

  getAllResources(page: number = 1, filter: any = {}): void {
    this.resourceService.getAll({page: page, ...filter})
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: any) => {
        this.resourceList = response.data;
        this.metadata = response;
      }, (error) => this.isError = true)
  }

  openResourceModal(content: HTMLElement, type: string, id: any = undefined): void {
    if(id) this.selectedResourceId = id;
    
    this.modal.open(content, { size: ((type == "delete") ? undefined : 'xl'), centered: true,
      beforeDismiss: () => {
        this.selectedResourceId = undefined;
        this.getAllResources();
        return true;
      } })
  }
  
  cloneObject(item: any): any {
    return JSON.parse(JSON.stringify(item));
  }

  closeConfirmModal(modal:NgbActiveModal, confirmed:boolean): void {
    if(confirmed) this.deleteResource();
    modal.close(confirmed);
  }

  deleteResource(): void {
    this.resourceService.delete(this.selectedResourceId)
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: Response) => {
        this.resourceList = response['data'];
        this.selectedResourceId = undefined;
        this.getAllResources();
      }, error => {
        this.isError = error;
        console.warn(error);
      })
  }

}
