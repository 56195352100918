<div class="fluid-container p-3">
  <div class="d-flex justify-content-end">
    <button type="button" (click)="closeAction.emit(true)" class="btn btn-outline-danger"><i
      class="fas fa-times fa-fw"></i></button>
  </div>
  <div class="row py-3">
    <div class="col-11 mx-auto">
      <h3>الطلاب</h3>
      <h5 class="text-secondary">
        {{ parentData ? parentData.name : 'إسم ولي الأمر' }}
      </h5>
    </div>
  </div>
  <div class="row py-3" *ngIf="parentData && !parentData?.students.length">
    <div class="col-11 text-center mx-auto">
      <h3>لا يوجد بيانات</h3>
    </div>
  </div>

  <div class="row py-3">
    <div *ngIf="loading && !parentData?.students?.length"
        class="spinner-border text-primary p-5 mx-auto" role="status">
      <span class="sr-only"></span>
    </div>
    <div class="col-11 mx-auto" *ngIf="parentData?.students">
      <div class="row" *ngFor="let student of parentData.students">
        <div class="col-3 h5">
          {{ student.name }}
        </div>
        <div class="col-2 h6 text-center">
          <div>{{ student.gender == 'female' ? 'انثى' : 'ذكر' }}</div>
          <div>{{ student.relation }}</div>
        </div>
        <div class="col-2 text-center">
          <div>{{ student.class ? student.class?.name : 'لا يوجد' }}</div>
          <div>{{
            student.class ? 
            ((student.class?.school_level ? student.class?.school_level?.name?.ar : undefined) + ' - ' +
            (student.class?.year ? ( 'العام ' + student.class?.year) : undefined)) : undefined
          }}</div>
        </div>
        <div class="col-3">
          {{ student.notes ? ((student.notes | slice:0:40) + (student.notes?.length > 40 ? '...' : '')) : 'لا يوجد' }}
        </div>
        <div class="col-1">
          <button (click)="editStudent(student)" [disabled]="loading"
                  class="btn btn-sm btn-primary text-light w-100">
            {{ loading ? '' : 'تعديل' }}

            <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
              <span class="sr-only"></span>
            </div>
          </button>
        </div>
        <div class="col-1">
          <button (click)="deleteStudent(student.id)" [disabled]="loading"
                  class="btn btn-sm btn-danger text-light w-100">
            {{ loading ? '' : 'حذف' }}

            <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
              <span class="sr-only"></span>
            </div>
          </button>
        </div>
        <hr>
      </div>
    </div>
  </div>

  <div class="row py-3">
    <div class="col-11 mx-auto">

      <form #resourceForm="ngForm" (submit)="submitResourceForm(resourceForm)">
        <div class="row py-3">
          <div class="col-3">
            <input required dir="rtl"
                   type="text"
                   name="name"
                   [(ngModel)]="resourceData.name"
                   placeholder="الإسم"
                   class="form-control">
          </div>

          <div class="col-3">
            <select name="relation" id="relation" class="form-select" [(ngModel)]="resourceData.relation">
              <option selected disabled hidden value="">العلافة</option>
              <option>اب</option>
              <option>ام</option>
              <option>اخ</option>
              <option>اخت</option>
              <option>عم</option>
              <option>عمة</option>
              <option>خال</option>
              <option>خالة</option>
              <option>جد</option>
              <option>جدة</option>
              <option>نسيب</option>
              <option>اخرى</option>
            </select>
          </div>

          <div class="col-2">
            <!-- Hidden Inputs -->
            <input type="text" name="parent_school_id" style="display: none;" [(ngModel)]="resourceData.parent_school_id">
            <input type="text" name="gender" style="display: none;" [(ngModel)]="resourceData.gender">

            <div class="btn-group m-1 w-100 rounded" role="group">
                <div class="btn w-100"
                    [class.btn-primary]="resourceData.gender == 'male'"
                    [class.btn-secondary]="resourceData.gender != 'male'"
                    (click)="resourceData.gender = 'male'">ذكر
                </div>
                <div class="btn w-100"
                     [class.btn-primary]="resourceData.gender == 'female'"
                     [class.btn-secondary]="resourceData.gender != 'female'"
                     (click)="resourceData.gender = 'female'">انثى
                </div>
            </div>
          </div>

          <div class="col-4">
            <input dir="rtl"
                   type="text"
                   name="notes"
                   [(ngModel)]="resourceData.notes"
                   placeholder="ملاحظات"
                   class="form-control">
          </div>
          
          <div class="col-3">
            <select required dir="rtl"
                    type="text"
                    [(ngModel)]="resourceData.school_level_id"
                    name="school_level_id"
                    class="form-select">
                <option selected disabled hidden [value]="undefined">المرحلة الدراسية</option>
                <option *ngFor="let level of schoolLevels" [value]="level.id">{{level.label}}</option>
            </select>
          </div>

          <div class="col-3" *ngIf="resourceData.school_level_id != 'undefined'"> 
            <select required dir="rtl"
                    type="text"
                    [(ngModel)]="resourceData.year"
                    name="year"
                    class="form-select">
                <option selected disabled hidden [value]="undefined">العام</option>
                <option *ngFor="let year of getKeys(classList, resourceData?.school_level_id)"
                        [value]="year">{{year}}</option>
            </select>
          </div>

          <div class="col-3" *ngIf="resourceData.school_level_id !='undefined' && resourceData.year !='undefined'">
            <select required dir="rtl"
                    type="text"
                    [(ngModel)]="resourceData.school_class_id"
                    name="school_class_id"
                    class="form-select">
                <option selected disabled hidden [value]="undefined">الفصل</option>
                <option *ngFor="let class of levelYearClassList" [value]="class.id">{{class.name}}</option>
            </select>
          </div>

          <div class="col-3">            
            <div class="btn-group m-1 w-100 rounded" role="group">
              <button type="submit" [disabled]="!resourceForm.valid || loading" class="btn btn-primary text-light w-100">
                {{ loading ? '' : (selectedStudentId ? 'تعديل' : 'حفظ') }}
  
                <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              </button>
              <button type="button" (click)="clearForm(resourceForm)"
                      class="btn btn-primary w-100">
                {{'تفريغ'}}
              </button>
            </div>
          </div>

        </div>
      </form>

    </div>
  </div>
</div>
