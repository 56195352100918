import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss']
})
export class LoginScreenComponent implements OnInit {

  loading: boolean = false;

  formData: any = {
    email: '',
    password: ''
  } 

  constructor(public router: Router, private authService: AuthService, private localizationService: LocalizationService) {
  }

  ngOnInit(): void { }

  login(loginForm): void {    
    this.authService.postAuth(this.formData)
      .pipe(ob => {this.loading = true; return ob;}, finalize(() => this.loading = false))
      .subscribe( response => {
        this.formData = { email: '', password: '' };

        this.router.navigate(['']);

      }, error => console.log("Error: ", error))
  }

}
