import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { SchoolLevels } from 'src/app/interfaces/class.interface';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { DefaultNotification, Notification } from 'src/app/interfaces/notification.interface';
import { ClassService } from 'src/app/services/resources/class.service';
import { NotificationService } from 'src/app/services/resources/notification.service';

@Component({
  selector: 'app-notifications-main-screen',
  templateUrl: './notifications-main-screen.component.html',
  styleUrls: ['./notifications-main-screen.component.scss']
})
export class NotificationsMainScreenComponent implements OnInit {

  isLoading: boolean = false;
  isError: boolean = false;
  pageTitle: string = 'الاشعارات';

  resourceList: Notification[] = undefined;
  defaultResourceShape: Notification = DefaultNotification;

  metadata: MetaData;
  searchString: string;

  filter: any = {
    school_level_id: 'undefined',
    class_id: 'undefined',
    year: 'undefined',
  }

  schoolLevels: any[] = [
    {id: 1, label: "الإبتدائي"},
    {id: 2, label: "الإعدادي"},
    {id: 3, label: "الثانوي"}
  ];
  classList: SchoolLevels[];
  
  selectedResourceId: number = undefined;
  copyNotification: boolean = false;

  constructor(private resourceService: NotificationService,
              private classService: ClassService,
              private ngbModal: NgbModal) { }


  ngOnInit(): void {
    this.getAllResources();
    this.getAllClasses();
  }

  getAllResources(page: number = 1, filter: any = {}): void {
      this.resourceService.getAll({page: page, include: 'students', ...filter})
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: any) => {
        this.resourceList = response.data;
        this.metadata = response;
      }, (error) => this.isError = true)
  }

  getAllClasses(): void {
    this.classService.getAllByClass()
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: any) => this.classList = response.data, (error) => this.isError = true)
  }

  openResourceModal(content: HTMLElement, type: string, id: any = undefined): void {
    if(id)
      this.selectedResourceId = id;
    if(type == "copy")
      this.copyNotification = true;
    
    this.ngbModal
      .open(content, { size: ((type == "delete") ? undefined : 'xl'),  centered: true, beforeDismiss: () => {
        this.selectedResourceId = undefined;
        this.copyNotification = false;
        this.getAllResources();
        return true;
      } })
  }
  
  cloneObject(item: any): any {
    return JSON.parse(JSON.stringify(item));
  }

  closeConfirmModal(modal:NgbActiveModal, confirmed:boolean): void {
    if(confirmed) this.deleteResource();
    modal.close(confirmed);
  }

  deleteResource(): void {
      this.resourceService.delete(this.selectedResourceId)
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: Response) => {
        this.resourceList = response['data'];
        this.selectedResourceId = undefined;
        this.getAllResources();
      }, error => {
        this.isError = error;
        console.warn(error);
      })
  }

  getKeys(object: any): string[] {
    return Object.keys(object);
  }

  log(data: any): void {
    console.log(data);
  }

}
