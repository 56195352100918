<div class="fluid-container p-3">
    <div class="row p-3">
        <div class="col-md-2 col-6 text-center">
            <h3>الإستفسارات</h3>
        </div>
        <div class="col-md-2 col-6 text-center">
            <button class="btn btn-primary w-100"
                (click)="scrollChatToBottom()"
                [disabled]="chatIsAtBottom">الرجوع للأسفل
            </button>
        </div>
        <div class="col-8"></div>
    </div>
    <div class="row">
        <div class="col-11 mx-auto">
            <form #chatForm="ngForm" (submit)="submitChatMessage(chatForm)">
                <div class="row">
                    <div class="col-12" id="chat-holder"
                        style="max-height: 65vh; overflow-y: scroll;">
                        <div class="row" *ngIf="inquiriesList">
                            <div class="col-12 mx-auto text-center">
                                <button [disabled]="metadata.current_page == metadata.last_page" type="button"
                                    (click)="getInquiriesForClient(metadata.current_page + 1, selectedResourceId)"
                                    class="btn btn-success">
                                    {{ metadata.current_page == metadata.last_page ? 'نهاية الرسائل' : 'المزيد من الرسائل' }}
                                </button>
                                <div *ngIf="loading" class="spinner-border text-primary mx-3 spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>

                            <div class="col-12" *ngFor="let inquiry of inquiriesList">
                                <div class="btn mx-1 my-3" style="max-width: 65%;"
                                    [class.btn-primary]="inquiry.sent_by_admin"
                                    [class.btn-secondary]="!inquiry.sent_by_admin"
                                    [style.float]="inquiry.sent_by_admin? 'right' : 'left'">
                                    {{inquiry.body}}
                                </div>
                                <i class="fas fa-check-double"
                                    style="position: relative; bottom: -68%;"
                                    *ngIf="inquiry.status == 'seen'"
                                    [style.float]="inquiry.sent_by_admin? 'right' : 'left'"></i>
                                <i class="fas fa-check"
                                    style="position: relative; bottom: -68%;"
                                    *ngIf="inquiry.status == 'sent'"
                                    [style.float]="inquiry.sent_by_admin? 'right' : 'left'"></i>
                                
                            </div>

                        </div>
                        <loader-section
                            [loading]="!inquiriesList && loading"
                            [data]="inquiriesList"
                            [error]="error">
                        </loader-section>
                    </div>
                    <div class="col-12 my-3">
                        <input required
                            type="text"
                            name="title"
                            [(ngModel)]="message"
                            placeholder="الرسالة"
                            dir="rtl"
                            class="form-control">
                    </div>
                </div>

                <div class="row">
                    <div class="row w-100">
                        <div class="col-md m-2 mx-auto">
                            <button type="submit"
                                [disabled]="!message || loading"
                                class="btn btn-primary text-light w-100">
                                {{ loading ? '' : 'إرسال' }}
                                
                                <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>

                            </button>
                        </div>
                        <div class="col-md m-2 mx-auto">
                            <button type="button"
                                class="btn btn-danger text-light w-100"
                                (click)="closeAction.emit(true)">إلغاء</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>