<div class="fluid-container p-3">
    <div class="row mx-3">
        <div class="col-12">
            <h3>إشعار</h3>
        </div>
    </div>
    <form #resourceForm="ngForm" (submit)="submitResourceForm(resourceForm)">

    <div class="row mx-3">
        <div class="col-4 my-3">
            <div class="btn-group w-100" role="group" style="border-radius: 10px; overflow: hidden;">
                <button type="button"
                    [class]="'btn ' + (resourceData.type =='تهنئة' ? 'btn-success' : 'btn-secondary')"
                    (click)="resourceData.type = 'تهنئة'">
                    تهنئة
                </button>
                <button type="button"
                    [class]="'btn ' + (resourceData.type =='تنويه' ? 'btn-success' : 'btn-secondary')"
                    (click)="resourceData.type = 'تنويه'">
                    تنويه
                </button>
                <button type="button"
                    [class]="'btn ' + (resourceData.type =='انذار' ? 'btn-success' : 'btn-secondary')"
                    (click)="resourceData.type = 'انذار'">
                    انذار
                </button>
            </div>
        </div>
        
        <div class="col-4 my-3">
            <input required dir="rtl"
                type="text"
                name="body"
                [(ngModel)]="resourceData.body"
                placeholder="تفاصيل"
                class="form-control">
        </div>
        
        <div class="col-4 my-3">
            <div class="btn-group w-100" role="group" style="border-radius: 10px; overflow: hidden;">
                <button type="button"
                    [class]="'btn ' + (resourceData.status == 'public' ? 'btn-success' : 'btn-secondary')"
                    (click)="resourceData.status = 'public'">
                    إرسال فوري
                </button>
                <button type="button"
                    [class]="'btn ' + (resourceData.status == 'draft' ? 'btn-success' : 'btn-secondary')"
                    (click)="resourceData.status = 'draft'">
                    مسودة
                </button>
            </div>
        </div>
    </div>

    <div class="row mx-3">
        <div class="col-7 limited-scroll">
            <div class="row mb-2">
                <div class="col-4 my-auto">            
                    <div class="h3 my-3">قائمة الطلبة</div>
                </div>

                <div class="col-4 my-auto">
                    <select class="form-select form-select-sm"
                        (change)="getStudents()"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="searchForm['filter[gender]']">
                        <option value="" id="" active> كل جنس </option>
                        <option value="male" id=""> ذكر </option>
                        <option value="female" id=""> انثى </option>
                    </select>
                </div>

                <div class="col-4 my-auto">
                    <input type="text" class="form-control form-control-sm"
                        placeholder="الإســم" (change)="getStudents()"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="searchForm['filter[name]']">
                </div>

                <div class="col-3 my-auto">
                  <select class="form-select form-select-sm"
                        (change)="getStudents()"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="searchForm['filter[class.school_level_id]']">
                      <option value="" id="" active>كل المرحلة الدراسية</option>
                      <option *ngFor="let level of schoolLevels" [value]="level.id">{{level.label}}</option>
                  </select>
                </div>
      
                <div class="col-3 my-auto" *ngIf="searchForm['filter[class.school_level_id]'] != 'undefined'"> 
                  <select class="form-select form-select-sm"
                        (change)="getStudents()"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="searchForm['filter[class.year]']">
                        <option value="" id="" active>كل عام</option>
                      <option *ngFor="let year of getKeys(classList, searchForm['filter[class.school_level_id]'])"
                              [value]="year">{{year}}</option>
                  </select>
                </div>
      
                <div class="col-3 my-auto" *ngIf="searchForm['filter[class.school_level_id]'] !='undefined' && searchForm['filter[class.year]'] !='undefined'">
                  <select class="form-select form-select-sm"
                        (change)="getStudents()"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="searchForm['filter[class.id]']">
                      <option value="" id="" active>كل فصل</option>
                      <option *ngFor="let class of levelYearClassList" [value]="class.id">{{class.name}}</option>
                  </select>
                </div>
      
                <div class="col-3 my-auto" *ngIf="studentsList?.length">
                    <button type="button" class="btn btn-sm btn-success w-100"
                        (click)="addSelectedStudents(studentsList)">
                        إختيار الكل
                    </button>
                </div>
            </div>
            
            <div class="row" *ngFor="let student of studentsList">
                <div class="col-3 h6">{{ student.name }}</div>
                <div class="col-3 h6">{{ student.gender == 'female' ? 'انثى' : 'ذكر' }}</div>
                <div class="col-3 h6">
                    <b *ngIf="student.school_level_id || student.year">
                        <span class="badge badge-sm bg-primary mx-auto">
                            {{ student.class.school_level.name.ar }} / {{ student.year }}
                        </span>
                    </b>
                    <b *ngIf="!student.school_level_id && !student.year">                        
                        <span class="badge badge-sm bg-primary mx-auto">
                            لا يوجد
                        </span>
                    </b>
                </div>  
                <div class="col-3 h6">
                    <button type="button" class="btn btn-sm btn-success mx-auto"
                        [disabled]="checkIfAlreadySelected(student.id)"
                        (click)="chosenStudentsList.push(student)">
                        + إختيار
                    </button>
                </div>                  
            </div>
            <paginator-component
                [metadata]="studentsMetadata"
                (getNewPage)="getStudents($event)">
            </paginator-component>
            <loader-section
                [loading]="loading"
                [data]="studentsList"
                [error]="error">
            </loader-section>
        </div>
        <div class="col-5 limited-scroll">
            <div class="row">
                <div class="col-8">
                    <div class="h3 my-3">الطلبة المختارين</div>
                </div>
                <div class="col-4 my-auto">
                    <button type="button" class="btn btn-sm btn-danger w-100"
                        (click)="chosenStudentsList = []">
                        إزالة الكل
                    </button>
                </div>
            </div>
            <div class="row" *ngFor="let student of chosenStudentsList; index as i">
                <div class="col-3 h6">{{ student.name }}</div>
                <div class="col-3 h6">{{ student.gender }}</div>
                <div class="col-3 h6">
                    <b *ngIf="student.school_level_id || student.year">
                        <span class="badge badge-sm bg-primary mx-auto">
                            {{ student.class.school_level.name.ar }} / {{ student.year }}
                        </span>
                    </b>
                    <b *ngIf="!student.school_level_id && !student.year">                        
                        <span class="badge badge-sm bg-primary mx-auto">
                            لا يوجد
                        </span>
                    </b>
                </div>
                <div class="col-3 h6">
                    <button type="button"
                        class="btn btn-sm btn-danger mx-auto"
                        (click)="chosenStudentsList.splice(i, 1)">
                    - إزالة
                    </button>
                </div>                              
            </div>
            <loader-section
                [loading]="loading"
                [data]="chosenStudentsList"
                [error]="error">
            </loader-section>
        </div>
    </div>

    <div class="row m-3">
        <div class="row w-100">
            <div class="col-md mx-auto" [class.d-none]="selectedResourceId && !copyNotification">
                <button type="submit"
                    [disabled]="!resourceForm.valid || loading || fileUploading"
                    class="btn btn-primary text-light w-100">
                    {{ loading || fileUploading ? '' : 'إرسال' }}

                    <div *ngIf="loading || fileUploading" class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only"></span>
                    </div>
                    {{ fileUploading ? 'بإنتظار رفع الملف' : '' }}

                </button>
            </div>
            
            <div class="col-md mx-auto" [class.d-none]="!selectedResourceId || copyNotification">
                <button type="submit"
                    [disabled]="!resourceForm.valid || loading || fileUploading"
                    class="btn btn-primary text-light w-100">
                    {{ loading || fileUploading ? '' : 'تعديل' }}
                    
                    <div *ngIf="loading || fileUploading" class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="sr-only"></span>
                    </div>
                    {{ fileUploading ? 'بإنتظار رفع الملف' : '' }}

                </button>
            </div>
            <div class="col-md mx-auto">
                <button type="button"
                    class="btn btn-danger text-light w-100"
                    (click)="closeAction.emit(true)">إلغاء
                </button>
            </div>
        </div>
    </div>
    </form>
</div>