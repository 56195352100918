import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MetaData } from 'src/app/interfaces/metadata.interface';

@Component({
  selector: 'paginator-component',
  templateUrl: './paginator-component.component.html',
  styleUrls: ['./paginator-component.component.scss']
})
export class PaginatorComponentComponent implements OnInit {

  @Input() metadata: MetaData;
  @Output() getNewPage = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }


  buildArray(number: number): number[] {
    return Array.from(Array(number).keys());
  }
}
