import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
// import { AuthService } from './auth.service';

@Injectable() 
export class HttpErrorInterceptor implements HttpInterceptor {

    errorMessage: any;
    isGetRequest: boolean;
    constructor(private router: Router,
                private toastrService: ToastrService,
                private authService: AuthService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        if (this.authService.getJwtToken()) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${this.authService.getJwtToken()}`
                }
            });
        }
        
        return next.handle(request)
            .pipe(
                map((res: any) => {
                    if (res["status"] >= 200 && res["status"] <= 205) {
                        request["method"] !== "GET" ? this.toastrService.success("تمت العملية بنجاح!"): res;
                        return res;
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    request["method"] === "GET" ? this.isGetRequest = true: this.isGetRequest = false;
                    switch(error.status){
                        case 0:
                            this.errorMessage = "مشاكل شبكة";
                            break;
                        case 301:
                            this.errorMessage = "الصفحة التي طلبتها تم حذفها";
                            break;
                        case 400: 
                            this.errorMessage = error?.error?.message;
                            break;
                        case 401:
                            this.errorMessage = "هذه العملية غير متاحة لك";
                            // this.router.navigate(['/login']);
                            break;
                        case 403: 
                            this.errorMessage = "هذه العملية غير متاحة لك";
                            // this.router.navigate(['/login']);
                            error.error.error === "Credentials didn't match the records" ? this.errorMessage = " يرجى التأكد من رقم الموظف أو كلمة السر" : this.errorMessage;
                            break;
                        case 404:
                            this.errorMessage = "لم توجد الصفحة";
                            break;
                        case 405:
                            this.errorMessage = error?.error?.message;
                            break;
                        case 422:
                            if(error?.error?.errors) {
                                if(Array.isArray(error.error.errors))
                                    this.errorMessage = error.error.errors[0];
                                else
                                    this.errorMessage = Object.values(error.error.errors)[0];
                            } else this.errorMessage = error.error.message;
                            break;
                        case 429:
                            this.errorMessage = "لقد قمت بإدخال عمليات كثيرة لا يستطيع الخادم على إستيعابها، إنتظر دقيقة واحدة.";
                            break;
                        case 500:
                            this.errorMessage = "خطأ من الخادم";
                            break;
                        default:
                            if (error.status >= 300 && error.status <= 399 ) {
                                this.errorMessage = "مشاكل تحويل";
                            } else if (error.status >= 400 && error.status <= 499 ) {
                                this.errorMessage = "مشاكل من جهة العميل";
                            } else if (error.status >= 500 && error.status <= 599 ) {
                                this.errorMessage = "مشاكل من جهة الخادم";
                            } else {
                                this.errorMessage = "خطأ غير معروف";
                            }
                            break;
                    }
                    
                    this.toastrService.error(this.errorMessage, " حدث خطأ " + error.status.toString(), {
                        positionClass: 'toast-top-center',
                        progressBar: true,
                        timeOut: 10000
                    });
                    
                    return throwError({errorStatus: error.status, errorMessage: this.errorMessage, isGetRequest: this.isGetRequest});
                }))
    }

    private addToken(request: HttpRequest<any>, token: string){
        return request.clone({
            setHeaders: {
                'Authorization':`Bearer ${token}` 
            }
        });
    }
}