import { NgModule } from '@angular/core';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgSelectModule } from '@ng-select/ng-select';

import { LoaderSectionComponent } from './components/loader-section/loader-section.component';
import { ConfirmQueryModalComponent } from './components/confirm-query-modal/confirm-query-modal.component';

import { PaginatorComponentComponent } from './components/paginator-component/paginator-component.component';
import { CommonModule } from '@angular/common';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    LoaderSectionComponent,
    ConfirmQueryModalComponent,
    PaginatorComponentComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  exports: [
    CommonModule,
    LoaderSectionComponent,
    ConfirmQueryModalComponent,
    PaginatorComponentComponent,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ToastrModule,
    TranslateModule,
    ServiceWorkerModule
  ],
  bootstrap: []
})
export class SharedModule { }
