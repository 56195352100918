<div class="container">
    <div class="row">
        <div class="col-11 my-5 mx-auto">
            <div class="h3">{{ pageTitle }}</div>
        </div>
    </div>
    
    <div class="col-11 mx-auto" *ngIf="true">
        <div class="card overflow-auto p-3">
            
            <div class="row m-2 text-center">
                <div class="col-md-1 col-2 px-0">
                    <span class="h1 text-primary">{{ metadata ? metadata.total : '...' }}</span>
                </div>
                <div style="width: fit-content" class="px-0 h1 text-primary"><b>|</b></div>
                <div class="col-md-1 col-2 px-0 pt-1 text-primary">
                    <div class="h6">إجمالي</div>
                    <div class="h6">الإستفسارات</div>
                </div>
            </div>
            <div class="row m-2">
                <div class="col-lg-3 col-md-5 col-sm-6">
                    <input required dir="rtl"
                        type="text"
                        name="search"
                        [(ngModel)]="searchString"
                        placeholder="بـحـث عن إستفسار"
                        class="form-control">
                </div>
                <div class="col-lg-1 col-md-2 col-sm-3">
                    <button class="btn btn-primary w-100"
                            (click)="getAllResources(1, {'filter[name]': searchString})">
                        <span *ngIf="!isLoading">إسم</span>
                        <div *ngIf="isLoading" class="spinner-border text-light spinner-border-sm">
                            <span class="sr-only"></span>
                        </div>
                    </button>
                </div>
                <div class="col-lg-1 col-md-2 col-sm-3">
                    <button class="btn btn-primary w-100"
                            (click)="getAllResources(1, {'filter[phone]': searchString})">
                        <span *ngIf="!isLoading">رقم</span>
                        <div *ngIf="isLoading" class="spinner-border text-light spinner-border-sm">
                            <span class="sr-only"></span>
                        </div>
                    </button>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <div class="row m-3" *ngFor="let resource of resourceList">
                        <div class="col-2 px-0 my-auto">
                            <div class="h5">{{ resource.name }}</div>
                            <div class="h6 text-secondary">{{ resource.phone }}</div>
                        </div>
                        <div class="col-3 px-0 my-auto text-center" style="direction: ltr;">
                            <div class="h6">
                                <span *ngIf="!resource.latest_inquiry?.sent_by_admin"
                                      class="badge rounded-pill bg-warning text-light mx-1">
                                      من ولي الأمر
                                </span>
                                <span *ngIf="resource.latest_inquiry?.sent_by_admin"
                                      class="badge rounded-pill bg-success mx-1">
                                      من مدير النظام
                                </span>
                                <span *ngIf="resource.latest_inquiry?.status == 'sent'"
                                      class="badge rounded-pill bg-primary mx-1">
                                    لم يقرء بعد
                                </span>
                                <span *ngIf="resource.latest_inquiry?.status == 'seen'"
                                      class="badge rounded-pill bg-secondary mx-1">
                                      مقروء
                                </span>
                            </div>
                            <div class="h6 text-secondary">
                                {{ resource.latest_inquiry?.updated_at | date: 'short' }}
                            </div>
                        </div>
                        <div class="col-4 px-1 my-auto text-center">
                            <div class="h6">
                                {{(resource?.latest_inquiry?.body | slice:0:80)+(resource?.latest_inquiry?.body.length > 25 ? '...' : '') }}
                            </div>
                        </div>
                        <div class="col-3 px-0 my-auto text-center">
                            <div class="btn-group m-1 w-100 rounded" role="group">
                                <div class="btn btn-primary px-2 w-100"
                                    (click)="openResourceModal(resourceModal, 'edit', resource.id)">
                                    رد
                                </div>
                                <div class="btn btn-secondary px-2 w-100"
                                    (click)="openResourceModal(deleteModal, 'edit', resource.id)">
                                    مقروء
                                </div>
                            </div>
                        </div>
                    </div>

                    <paginator-component
                        [metadata]="metadata"
                        (getNewPage)="getAllChats($event)">
                    </paginator-component>
                    
                </div>
            </div>

            <loader-section
                [loading]="isLoading"
                [data]="resourceList"
                [error]="isError">
            </loader-section>
            
        </div>
    </div>

    <ng-template #resourceModal let-modal>
        <app-inquiries-form-modal
            [selectedResourceId]="selectedResourceId"
            (closeAction)="modal.dismiss(e)">
        </app-inquiries-form-modal>
    </ng-template>

    <ng-template #deleteModal let-modal>
        <confirm-query-modal
            [title]="'تعيين كمقروء'"
            [body]="'هل تريد تعيين الكل كمقروء؟'"
            (confirm)="closeConfirmModal(modal, true)"
            (cancel)="closeConfirmModal(modal, false)"
            (close)="modal.close(e)"
            (dismiss)="modal.dismiss(e)">
        </confirm-query-modal>
    </ng-template>

</div>