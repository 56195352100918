<div class="fluid-container p-3">
    <div class="row">
        <div class="col-11 mx-auto">
            <h3>إضافة فصل</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-11 mx-auto">
            <form #resourceForm="ngForm" (submit)="submitResourceForm(resourceForm)">
                <div class="row">
                    <div class="col-4 mx-auto mx-3 my-4">
                        <input required dir="rtl"
                            type="text"
                            name="name"
                            [(ngModel)]="resourceData.name"
                            placeholder="الإسم"
                            class="form-control">
                    </div>
                    <div class="col-4 mx-auto mx-3 my-4">
                        <select class="form-select" required name="school_level_id"
                                placeholder="المستوى الدراسي"
                                [ngModel]="resourceData.school_level_id">
                            <option value="1">الإبتدائي</option>
                            <option value="2">الإعدادي</option>
                            <option value="3">الثانوي</option>
                        </select>
                    </div>
                    <div class="col-4 mx-auto mx-3 my-4">
                        <input required dir="rtl"
                            type="number" name="year"
                            [(ngModel)]="resourceData.year"
                            placeholder="العام الدراسي"
                            class="form-control">
                    </div>
                </div>
                
                <div class="row py-3">
                    <div class="row w-100">
                        <div class="col-md mx-auto" [class.d-none]="selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading"
                                class="btn btn-primary text-light w-100">
                                {{ loading ? '' : 'إضافة' }}

                                <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>
                        
                        <div class="col-md mx-auto" [class.d-none]="!selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading"
                                class="btn btn-primary text-light w-100">
                                {{ loading ? '' : 'تعديل' }}
                                
                                <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </button>
                        </div>

                        <div class="col-md mx-auto">
                            <button type="button"
                                class="btn btn-danger text-light w-100"
                                (click)="closeAction.emit(true)">إلغاء
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>