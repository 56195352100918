import { LoggedInRootScreenComponent } from './screens/logged-in-root-screen/logged-in-root-screen.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginScreenComponent } from './screens/auth/login-screen/login-screen.component';
import { AuthGuard } from './guards/auth.guard';

import { StudentGuardianMainScreenComponent } from './screens/studentAndGuardian/student-guardian-main-screen/student-guardian-main-screen.component';
import { PeriodsMainScreenComponent } from './screens/periods/periods-main-screen/periods-main-screen.component';
import { ClassMainScreenComponent } from './screens/class/class-main-screen/class-main-screen.component';
import { NewsMainScreenComponent } from './screens/news/news-main-screen/news-main-screen.component';
import { InquiriesMainScreenComponent } from './screens/inquiries/inquiries-main-screen/inquiries-main-screen.component';
import { NotificationsMainScreenComponent } from './screens/notifications/notifications-main-screen/notifications-main-screen.component';
import { AlertsMainScreenComponent } from './screens/alerts/alerts-main-screen/alerts-main-screen.component';
import { AlertsPlaybackScreenComponent } from './screens/alerts/alerts-playback-screen/alerts-playback-screen.component';
import { NotSuperAdminGuard } from './guards/notsuperadmin.guard';
import { SuperAdminGuard } from './guards/superadmin.guard';

const routes: Routes = [
  {path: 'login', component: LoginScreenComponent},
  {path: '', component: LoggedInRootScreenComponent, canActivate: [AuthGuard],
  children: [
    {path: '', component: AlertsMainScreenComponent, canActivate: [NotSuperAdminGuard]},
    {path: 'alert-playback', component: AlertsPlaybackScreenComponent, canActivate: [NotSuperAdminGuard]},
    {path: 'student-and-guardian', component: StudentGuardianMainScreenComponent, canActivate: [NotSuperAdminGuard]},
    {path: 'periods', component: PeriodsMainScreenComponent, canActivate: [NotSuperAdminGuard]},
    {path: 'classes', component: ClassMainScreenComponent, canActivate: [NotSuperAdminGuard]},
    {path: 'news', component: NewsMainScreenComponent, canActivate: [NotSuperAdminGuard]},
    {path: 'inquiries', component: InquiriesMainScreenComponent, canActivate: [NotSuperAdminGuard]},
    {path: 'notifications', component: NotificationsMainScreenComponent, canActivate: [NotSuperAdminGuard]},
    {path: 'superadmin', canActivate: [SuperAdminGuard], loadChildren: () => import('./superadmin/superadmin.module').then(m => m.SuperAdminModule)},
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
