import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { DefaultSchool, School } from 'src/app/superadmin/interfaces/school.interface';
import { SchoolAdmin } from 'src/app/superadmin/interfaces/schooladmin.interface';
import { SchoolService } from 'src/app/superadmin/services/school.service';
import { SchoolAdminService } from 'src/app/superadmin/services/schooladmin.service';

@Component({
  selector: 'app-school-form',
  templateUrl: './school-form.component.html',
  styleUrls: ['./school-form.component.scss']
})
export class SchoolFormComponent implements OnInit {


  @Input() selectedResourceId: any = null;
  @Output() closeAction = new EventEmitter<boolean>(); 

  loading: boolean;
  error: boolean;

  schoolAdminList: SchoolAdmin[];
  selectedSchoolAdmins: SchoolAdmin[] = [];
  adminMetadata: MetaData;
  adminLoading: boolean;
  adminError: boolean;
  
  defaultResourceData: School = DefaultSchool; 
  resourceData: School =  JSON.parse(JSON.stringify(this.defaultResourceData));

  constructor(private resourceService: SchoolService, private schoolAdminService: SchoolAdminService) { }

  ngOnInit(): void {
    if(this.selectedResourceId) this.getResourceById(this.selectedResourceId);
    this.getSchoolAdmins();
  }

  getSchoolAdmins(page: number = 1): void {
    let params = {page: page};

    if(this.selectedResourceId)
      params['schoolId'] = this.selectedResourceId;
    else
      params['unassignedAdmins'] = true;

    this.schoolAdminService.getAll(params)
      .pipe(obs => { this.adminLoading = true; return obs })
      .pipe(finalize(() => this.adminLoading = false))
      .subscribe((response: any) => {
        this.schoolAdminList = response.data;
        this.adminMetadata = response;
      }, (error) => this.adminError = true)
  }

  selectedSchoolAdminsIncludes(id: number): boolean {
    return this.selectedSchoolAdmins ? this.selectedSchoolAdmins.map(s => s.id).includes(id) : false;
  }


  getResourceById(id: any) {
    this.resourceService.getSpecific(id)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => {
        this.resourceData = response.data;
        this.selectedSchoolAdmins = response.data.school_admins;
      }, error => this.error = true)
  }

  submitResourceForm(resourceForm: NgForm): void {
    resourceForm.value.school_admin_ids = this.selectedSchoolAdmins.map(s => s.id);
    if(this.selectedResourceId) this.submitUpdate(this.selectedResourceId, resourceForm);
    else this.submitNew(resourceForm);
  }

  submitUpdate(id: any, form: NgForm): void {
    this.resourceService.patch(id, form.value)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }

  submitNew(form: NgForm): void {
    this.resourceService.post(form.value)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }

}
