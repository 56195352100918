import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Inquiry } from 'src/app/interfaces/inquiry.interface';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { InquiryService } from 'src/app/services/resources/inquiry.service';

@Component({
  selector: 'app-inquiries-form-modal',
  templateUrl: './inquiries-form-modal.component.html',
  styleUrls: ['./inquiries-form-modal.component.scss']
})
export class InquiriesFormModalComponent implements OnInit {

  @Input() selectedResourceId: any = null;
  @Output() closeAction = new EventEmitter<boolean>(); 

  loading: boolean;
  error: boolean;

  message: string;

  inquiriesList: Inquiry[];
  metadata: MetaData;
  
  chatContainer: HTMLElement;
  chatIsAtBottom: boolean = false;
  
  constructor(private inquiryService: InquiryService, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.chatContainer = document.querySelector("#chat-holder");
    this.seenAll();
    if(this.selectedResourceId) this.getInquiriesForClient(1, this.selectedResourceId);
    this.renderer.listen(this.chatContainer, 'scroll', (event) => this.chatAtBottom(event));
  }

  submitChatMessage(messageForm: NgForm): void {
    this.loading = true;
    let body = { parent_school_id: this.selectedResourceId, body: this.message };

    this.inquiryService.post(body)
      .pipe(finalize(() => this.loading = false))
      .subscribe(response => {
        this.getInquiriesForClient(1, this.selectedResourceId);
        messageForm.resetForm();
      }, error => this.error = true)
  }

  getInquiriesForClient(page: number = 1, parentId: number): void {
    this.loading = true;
    this.inquiryService.getAll({page: page, 'filter[parent_school_id]': parentId})
      .pipe(finalize(() => {
        this.loading = false;
        if(page == 1) setTimeout(() => this.scrollChatToBottom(), 10);
      }))
      .subscribe((response) => {
        this.metadata = response;
        if(page == 1) this.inquiriesList = response.data.reverse();
        if(page > 1) this.inquiriesList = [...response.data.reverse(), ...this.inquiriesList];
      }, error => error = true);
  }

  scrollChatToBottom(): void { 
    let chatTotalHeight: number = this.chatContainer.scrollHeight;
    let chatVisibleHeight: number = this.chatContainer.clientHeight;
    this.chatContainer.scrollTop = chatTotalHeight - chatVisibleHeight;
  }

  seenAll(): void {
    this.inquiryService.seeAll({parent_school_id: this.selectedResourceId})
      .subscribe((response) => console.log("seenAll ", response), error => this.error = true);
  }

  chatAtBottom(event: any = undefined): void {
    let container: HTMLElement = document.querySelector("#chat-holder");
    let chatCurrentPosition: number = Math.floor(container.scrollTop);

    this.chatIsAtBottom = chatCurrentPosition == (container.scrollHeight - container.clientHeight);

    if(this.metadata && chatCurrentPosition == 0 && this.metadata.current_page < this.metadata.last_page)
      this.getInquiriesForClient(this.metadata.current_page + 1, this.selectedResourceId);
    
  }

}
