import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-logged-in-root-screen',
  templateUrl: './logged-in-root-screen.component.html',
  styleUrls: ['./logged-in-root-screen.component.scss']
})
export class LoggedInRootScreenComponent implements OnInit {

  sidebar_routes = [];

  schooladmin_routes = [{
      title: 'إدارة النداءات',
      icontype: 'fontawesome' ,
      icon: 'fas fa-th-large',
      active: true,
      link: '/'
    },{
      title: 'تشغيل النداءات',
      icontype: 'fontawesome' ,
      icon: 'fas fa-volume-up', 
      active: true,
      link: '/alert-playback'
    },{
      title: 'إدارة أولياء الأمور والطلبة',
      icontype: 'fontawesome' ,
      icon: 'fas fa-user-graduate', 
      active: true,
      link: '/student-and-guardian'
    },{
      title: 'إدارة الفترات',
      icontype: 'fontawesome' ,
      icon: 'fas fa-clock', 
      active: true,
      link: '/periods'
    },{
      title: 'الأخبار',
      icontype: 'fontawesome',
      icon: 'fas fa-newspaper', 
      active: true,
      link: '/news'
    },{
      title: 'تسجيل الفصول',
      icontype: 'fontawesome',
      icon: 'fas fa-school', 
      active: true,
      link: '/classes'
    },{
      title: 'الإستفسارات',
      icontype: 'fontawesome',
      icon: 'fas fa-users', 
      active: true,
      link: '/inquiries'
    },{
      title: 'الاشعارات',
      icontype: 'fontawesome',
      icon: 'fas fa-cogs', 
      active: true,
      link: '/notifications'
    }
  ];

  superadmin_routes = [{
    title: 'إدارة المدارس',
    icontype: 'fontawesome' ,
    icon: 'fas fa-school', 
    active: true,
    link: '/superadmin/school'
  },{
    title: 'إدارة مستخدمين المدارس',
    icontype: 'fontawesome' ,
    icon: 'fas fa-users', 
    active: true,
    link: '/superadmin/schooladmin'
  }];

  sidebar_config = {
    icontype: 'fontawesome' ,
    header: {
      title: '',
      icon: null,
      image: 'assets/img/hudhud_logo.png'
    },
    footer: {
      button: true,
      title: 'تسجيل الخروج',
      icon: 'fas fa-sign-out-alt',
    }
  };

  isEnglish: boolean = false;

  constructor(private router: Router, private authService: AuthService, private localizationService: LocalizationService) { }

  ngOnInit(): void {
    if(this.authService.isSuperAdmin())
      this.sidebar_routes = this.superadmin_routes;
    else
      this.sidebar_routes = this.schooladmin_routes;
    // this.isEnglish = this.localizationService.isEnglish;
  }


  sidebarClicked(item_title: string): void {
    if(item_title == 'تسجيل الخروج')  this.logOut();
  }

  logOut(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }

}
