import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { DefaultSchoolAdmin, SchoolAdmin } from 'src/app/superadmin/interfaces/schooladmin.interface';
import { SchoolAdminService } from 'src/app/superadmin/services/schooladmin.service';

@Component({
  selector: 'app-schooladmin-form',
  templateUrl: './schooladmin-form.component.html',
  styleUrls: ['./schooladmin-form.component.scss']
})
export class SchooladminFormComponent implements OnInit {

  @Input() selectedResourceId: any = null;
  @Output() closeAction = new EventEmitter<boolean>(); 

  loading: boolean;
  error: boolean;
  editPassword: boolean = false;

  defaultResourceData: SchoolAdmin = DefaultSchoolAdmin; 
  resourceData: SchoolAdmin =  JSON.parse(JSON.stringify(this.defaultResourceData));

  constructor(private resourceService: SchoolAdminService) { }

  ngOnInit(): void {
    if(this.selectedResourceId) this.getResourceById(this.selectedResourceId);
  }

  getResourceById(id: any) {
    this.resourceService.getSpecific(id)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.resourceData = response.data, error => this.error = true)
  }

  submitResourceForm(resourceForm: NgForm): void {
    if(this.selectedResourceId) this.submitUpdate(this.selectedResourceId, resourceForm);
    else this.submitNew(resourceForm);
  }

  submitUpdate(id: any, form: NgForm): void {
    this.resourceService.patch(id, form.value)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }

  submitNew(form: NgForm): void {
    this.resourceService.post(form.value)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }

}
