export interface SchoolLevelsGuide {
    level: string,
    name: string,
    years: number[]
}
export interface SchoolLevels {
    1: {
        1: Class[],
        2: Class[],
        3: Class[],
        4: Class[],
        5: Class[],
        6: Class[]
    },
    2: {
        1: Class[],
        2: Class[],
        3: Class[]
    },
    3: {
        1: Class[],
        2: Class[],
        3: Class[]
    }
}

export interface Class {
    id?: number,
    name: string,
    year: string,
    school_id: number,
    school_level_id: number,
    created_at?: string,
    updated_at?: string
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultClass = {
    id: undefined,
    name: '',
    year: '',
    school_id: undefined,
    school_level_id: undefined,
}