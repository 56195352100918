import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize, take, tap} from 'rxjs/operators';
import {Alert, DefaultAlert} from 'src/app/interfaces/alert.interface';
import {Class} from 'src/app/interfaces/class.interface';
import {MetaData} from 'src/app/interfaces/metadata.interface';
import {AlertService} from 'src/app/services/resources/alert.service';
import {ClassService} from 'src/app/services/resources/class.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireDatabase} from '@angular/fire/database';

@Component({
  selector: 'app-alerts-main-screen',
  templateUrl: './alerts-main-screen.component.html',
  styleUrls: ['./alerts-main-screen.component.scss']
})
export class AlertsMainScreenComponent implements OnInit {

  isLoading: boolean = false;
  isError: boolean = false;
  pageTitle: string = 'إدارة النداءات';

  resourceList: Alert[] = undefined;
  defaultResourceShape: Alert = DefaultAlert;

  selectedResourceId: number = undefined;
  selectedResourceData: Alert = undefined;
  selectedSorting: string;
  metadata: MetaData;

  classRooms: Class[];
  selectedClassroom: any;

  genders: any[] = [{
    id: 1,
    label: 'أنثى',
    title: 'female'
  }, {
    id: 2,
    label: 'ذكر',
    title: 'male'
  }];
  selectedGender: any;

  constructor(
    private resourceService: AlertService,
    private classService: ClassService,
    private ngbModal: NgbModal,
  ) {
  }



  ngOnInit(): void {
    this.getAllResources('all');
    this.getAllClasses();
  }


  getAllResources(type: string = this.selectedSorting, page: number = 1): void {
    this.isLoading = true;
    let query: any = {page: page};
    this.selectedSorting = type;

    if (type && type != 'all') {
      query = {...query, 'filter[status]': this.selectedSorting};
    }

    this.resourceService.getAll(query)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((response: any) => {
        this.resourceList = response.data;
        this.metadata = response;
      }, (error) => this.isError = false);
  }

  getAllClasses(): void {
    this.isLoading = true;

    this.classService.getAll()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((response: any) => this.classRooms = response.data, (error) => console.warn(error));
  }

  openResourceModal(content: HTMLElement, size: string = 'xl', id: any = undefined): void {
    if (id) {
      this.selectedResourceId = id;
    }

    this.ngbModal
      .open(content, {
        size: size, centered: true, beforeDismiss: () => {
          this.selectedResourceId = undefined;
          return true;
        }
      });
  }

  cloneObject(item: any): any {
    return JSON.parse(JSON.stringify(item));
  }

  search(event: any): void {
    console.log('search event: ', event);
  }

  getNames(data: any[], nestedStudents): string {
    if (!data?.length) {
      return '';
    }

    let namesString: string = '';
    if (nestedStudents) {
      data.map((obj, index) => index == 0 ? namesString += `${obj.parent_school_student.name} ` : namesString += `- ${obj.parent_school_student.name}`);
    } else {
      data.map((student, index) => index == 0 ? namesString += `${student.name} ` : namesString += `- ${student.name}`);
    }

    return namesString;
  }

  playAudio(url: string): void {

  }

  closeCallbackModal(modal: NgbActiveModal, confirmed: boolean, confirmedCallback): void {
    if (confirmed) {
      confirmedCallback;
    }
    modal.close(confirmed);
  }

  pendingCall(): void {
    this.isLoading = true;
    this.resourceService.pendingCall(this.selectedResourceId)
      .pipe(finalize(() => this.getAllResources()))
      .subscribe((response: Response) => this.resourceList = response['data']);
  }

  approveCall(): void {
    this.isLoading = true;
    this.resourceService.approveCall(this.selectedResourceId)
      .pipe(finalize(() => this.getAllResources()))
      .subscribe((response: Response) => this.resourceList = response['data']);
  }

  declineCall(): void {
    this.isLoading = true;
    this.resourceService.declineCall(this.selectedResourceId)
      .pipe(finalize(() => this.getAllResources()))
      .subscribe((response: Response) => this.resourceList = response['data']);
  }

  deleteCall(): void {
    this.isLoading = true;
    this.resourceService.delete(this.selectedResourceId)
      .pipe(finalize(() => this.getAllResources()))
      .subscribe((response: Response) => this.resourceList = response['data']);
  }

}
