import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { DefaultParent, Parent } from 'src/app/interfaces/parent.interface';
import { ParentService } from 'src/app/services/resources/parent.service';

@Component({
  selector: 'app-guardian-form-modal',
  templateUrl: './guardian-form-modal.component.html',
  styleUrls: ['./guardian-form-modal.component.scss']
})
export class GuardianFormModalComponent implements OnInit {

  @Input() selectedResourceId: any = null;
  @Output() closeAction = new EventEmitter<boolean>(); 

  loading: boolean;
  error: boolean;
  fileUploading: boolean;

  defaultResourceData: Parent = DefaultParent; 
  resourceData: Parent =  JSON.parse(JSON.stringify(this.defaultResourceData));

  constructor(private resourceService: ParentService) { }

  ngOnInit(): void {
    this.selectedResourceId && this.getResourceById(this.selectedResourceId);
  }

  getResourceById(id: any) {
    this.resourceService.getSpecific(id)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.resourceData = response.data, error => this.error = true)
  }

  submitResourceForm(resourceForm: NgForm): void {
    if(this.selectedResourceId) this.submitUpdate(this.selectedResourceId, resourceForm);
    else this.submitNew(resourceForm);
  }

  submitUpdate(id: any, form: NgForm): void {
    this.resourceService.patch(id, form.value)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }

  submitNew(form: NgForm): void {
    this.resourceService.post(form.value)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }

}
