export interface Inquiry {
    id?: number,
    body?: string,
    parent_school_id?: number,
    school_id?: number,
    sent_by_admin?: boolean,
    status?: string,
    created_at?: string,
    updated_at?: string
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultInquiry = {
    id: undefined,
}