import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class AlertService extends CentralService {

  protected resource: string = "call";  

  deleteCall(id: number): Observable<any> {
    return this.delete(id+'/queue-revoke');
  }

  getQueue(params: any = undefined): Observable<any> {
    return this.getAll({...params, with_call_queue: true});
  }

  pendingCall(id: number): Observable<any> {
    return this.patch(id, {status: "pending"});
  }

  approveCall(id: number): Observable<any> {
    return this.patch(id, {status: "approved"});
  }

  declineCall(id: number): Observable<any> {
    return this.patch(id, {status: "declined"});
  }

  registerFCM(fcm_token: string): Observable<any> {
    return this.postSpecific('register_fcm', {fcm_token});
  }

}
