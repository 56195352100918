<div class="fluid-container p-3">
    <div class="row">
        <div class="col-11 mx-auto">
            <h3>رفع ملف</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-11 mx-auto">
            <form #resourceForm="ngForm" (submit)="submitResourceForm(resourceForm, fileUpload)">
                <div class="row">
                    <div class="col-12 p-3">
                        <input [required]="!selectedResourceId"
                            type="file" required
                            name="file" accept="file"
                            [(ngModel)]="file"
                            placeholder="الملف" #fileUpload
                            class="form-control">
                    </div>
                </div>
                
                <div class="row py-3">
                    <div class="row w-100">
                        <div class="col-md mx-auto" [class.d-none]="selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading || fileUploading"
                                class="btn btn-primary text-light w-100">
                                {{ loading || fileUploading ? '' : 'إضافة' }}

                                <div *ngIf="loading || fileUploading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>
                                {{ fileUploading ? 'بإنتظار رفع الملف' : '' }}

                            </button>
                        </div>
                        
                        <div class="col-md mx-auto" [class.d-none]="!selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading || fileUploading"
                                class="btn btn-primary text-light w-100">
                                {{ loading || fileUploading ? '' : 'تعديل' }}
                                
                                <div *ngIf="loading || fileUploading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>
                                {{ fileUploading ? 'بإنتظار رفع الملف' : '' }}

                            </button>
                        </div>
                        <div class="col-md mx-auto">
                            <button type="button"
                                class="btn btn-danger text-light w-100"
                                (click)="closeAction.emit(true)">إلغاء</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>