export interface News {
    id?: number,
    title: string,
    status: string,
    code: string,
    body: string,
    image?: any,
    image_url?: string,
    image_id?: any,
    created_at?: string,
    updated_at?: string
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultNews = {
    id: undefined,
    title: '',
    status: 'active',
    code: '',
    image: undefined,
    body: '',
}