import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { News } from 'src/app/interfaces/news.interface';
import { NewsService } from 'src/app/services/resources/news.service';

@Component({
  selector: 'app-news-main-screen',
  templateUrl: './news-main-screen.component.html',
  styleUrls: ['./news-main-screen.component.scss']
})
export class NewsMainScreenComponent implements OnInit {
  
  isLoading: boolean = false;
  isError: boolean = false;
  pageTitle: string = 'الأخبار';

  resourceList: News[] = undefined;

  metadata: MetaData;
  searchString: string;

  selectedResourceId: number = undefined;

  filter = {
    status: undefined,
    title: undefined,
    body: undefined
  }
  clearedFilter = {
    status: undefined,
    title: undefined,
    body: undefined
  }

  constructor(private resourceService: NewsService, private modal: NgbModal) { }

  ngOnInit(): void {
    this.getAllResources();
  }

  getAllResources(page: number = 1): void {
    let query = {};

    if(this.filter.status) query["filter[status]"] = this.filter.status;
    if(this.filter.title) query["filter[title]"] = this.filter.title;
    if(this.filter.body) query["filter[body]"] = this.filter.body; 

    this.resourceService.getAll({page: page, ...query})
    .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: any) => {
        this.resourceList = response.data;
        this.metadata = response;
      }, (error) => this.isError = true)
  }

  openResourceModal(content: HTMLElement, type: string, id: any = undefined): void {
    if(id) this.selectedResourceId = id;

    this.modal.open(content, { size: ((type == "delete") ? undefined : 'xl'), centered: true, beforeDismiss: () => {
        this.selectedResourceId = undefined;
        this.getAllResources();
        return true;
      } })
  }
  
  cloneObject(item: any): any {
    return JSON.parse(JSON.stringify(item));
  }

  cleanImageURL(image_url: string): any {
    if(!image_url) return '';

    console.log("image_url ", image_url);
    console.log("image_url clean ", image_url.replaceAll(' ','%20'));
    return image_url.replaceAll(' ','%20');
  }
  
  cleanString(text: string): string {
    return text.replace(/(<([^>]+)>)/gi, "");
  }

  closeConfirmModal(modal:NgbActiveModal, confirmed:boolean): void {
    if(confirmed) this.deleteResource();
    modal.close(confirmed);
  }

  deleteResource(): void {
    this.resourceService.delete(this.selectedResourceId)
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: Response) => {
        this.resourceList = response['data'];
        this.selectedResourceId = undefined;
        this.getAllResources();
      }, error => {
        this.isError = error;
        console.warn(error);
      })
  }

  openImage(url: string): void {
    window.open(url, '_blank').focus();
  }

}
