import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class NewsService extends CentralService {

  protected resource: string = "school_news";

}
