<div class="container">
    <div class="row">
        <div class="col-11 my-5 mx-auto">
            <div class="h3">{{ pageTitle }}</div>
            <div class="btn btn-lg btn-primary p-3" (click)="openResourceModal(resourceModal, 'add')">
                إضافة خبر <i class="fas fa-plus mx-2"></i>
            </div>
        </div>
    </div>

    <div class="col-11 mx-auto" *ngIf="true">
        <div class="card overflow-auto p-3">
            <div class="row m-2 text-center">
                <div class="col-md-1 col-2 px-0">
                    <span class="h1 text-primary">{{ metadata ? metadata.total : '...' }}</span>
                </div>
                <div style="width: fit-content" class="px-0 h1 text-primary"><b>|</b></div>
                <div class="col-md-1 col-2 px-0 pt-1 text-primary">
                    <div class="h6">إجمالي</div>
                    <div class="h6">الأخبار</div>
                </div>
            </div>
            <div class="row m-2">
                <div class="col-3">
                    <input required dir="rtl"
                        type="text"
                        name="search"
                        [(ngModel)]="filter.title"
                        placeholder="العناوين"
                        class="form-control">
                </div>
                <div class="col-3">
                    <input required dir="rtl"
                        type="text"
                        name="search"
                        [(ngModel)]="filter.body"
                        placeholder="النصوص"
                        class="form-control">
                </div>
                <div class="col-3">
                    <div class="btn-group m-1 w-100 rounded " role="group" style="overflow: hidden">
                        <button [class]="'btn ' + (filter.status == 'active' ? 'btn-success' : 'btn-secondary')"
                                (click)="filter.status != 'active' ? filter.status = 'active' : filter.status = undefined">
                            <span>مفعل</span>
                        </button>
                        <button [class]="'btn ' + (filter.status == 'disabled' ? 'btn-danger' : 'btn-secondary')"
                                (click)="filter.status != 'disabled' ? filter.status = 'disabled' : filter.status = undefined">
                            <span>غير مفعل</span>
                        </button>
                    </div>
                </div>
                <div class="col-2">
                    <button class="btn btn-primary w-100" (click)="getAllResources(1)">
                        <span *ngIf="!isLoading">بحث</span>
                        <div *ngIf="isLoading" class="spinner-border text-light spinner-border-sm">
                            <span class="sr-only"></span>
                        </div>
                    </button>
                </div>
                <div class="col-1">
                    <button class="btn btn-outline-danger w-100" (click)="filter = clearedFilter">                        
                        <span *ngIf="!isLoading">تفريغ</span>
                        <div *ngIf="isLoading" class="spinner-border text-danger spinner-border-sm">
                            <span class="sr-only"></span>
                        </div>
                    </button>
                </div>
            </div>
            <div class="row m-3">
                <div class="col-12">
                    <div class="row my-4" *ngFor="let resource of resourceList">
                        <div class="col-2 px-0 my-auto text-center">
                            <div class="news_image" 
                                (click)="openImage(resource.image_url)"
                                [style.cursor]="resource.image_url ? 'pointer' : ''"
                                [style.background-image]="'url('+cleanImageURL(resource?.image_url)+')'">
                            </div>
                        </div>
                        <div class="col-2 px-2 my-auto">
                            <div class="h5">{{ resource.title }}</div>
                            <div class="hudhud-font-small">
                                {{(cleanString(resource.body) | slice:0:45) + (resource?.body?.length > 45 ? '...' : '')}}
                            </div>
                        </div>
                        <div class="col-2 px-0 my-auto text-center" style="direction: ltr;">
                            <div class="h5">{{ 'كود الخبر' }}</div>
                            <div class="h6">{{resource.code }}</div>
                        </div>
                        <div class="col-1 px-0 my-auto text-center">
                            <span class="badge bg-success" *ngIf="resource.status == 'active'">
                                مفعل
                            </span>
                            <span class="badge bg-danger" *ngIf="resource.status != 'active'">
                                غير مفعل
                            </span>
                        </div>
                        <div class="col-2 px-0 my-auto text-center">
                            <div class="h6">{{ resource.created_at | date: 'short' }}</div>
                        </div>
                        <div class="col-3 px-0 my-auto text-center">
                            <div class="btn-group m-1 w-100 rounded" role="group">
                                <div class="btn btn-primary px-2 w-100"
                                    (click)="openResourceModal(resourceModal, 'edit', resource.id)">
                                    تعديل
                                </div>
                                <div class="btn btn-danger px-2 w-100"
                                    (click)="openResourceModal(deleteModal, 'delete', resource.id)">
                                    حذف
                                </div>
                            </div>
                        </div>
                    </div>

                    <paginator-component
                        [metadata]="metadata"
                        (getNewPage)="getAllResources($event)">
                    </paginator-component>

                </div>
            </div>

            <loader-section
                [loading]="isLoading"
                [data]="resourceList"
                [error]="isError">
            </loader-section>

        </div>
    </div>

    <ng-template #resourceModal let-modal>
        <app-news-form-modal
            (closeAction)="modal.dismiss('closed')"
            [selectedResourceId]="selectedResourceId">
        </app-news-form-modal>
    </ng-template>

    <ng-template #deleteModal let-modal>
        <confirm-query-modal
            (confirm)="closeConfirmModal(modal, true)"
            (cancel)="closeConfirmModal(modal, false)"
            (close)="modal.dismiss('closed')"
            (dismiss)="modal.dismiss($event)">
        </confirm-query-modal>
    </ng-template>

</div>
