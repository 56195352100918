<div class="moud-uploader">
    <input name="image" #fileUpload
        type="file" accept="image"
        [required]="!required"
        [(ngModel)]="inputValue"
        (ngModelChange)="inputValueChange.emit(inputValue)"
        (change)="getImageIfAny(inputValue, fileUpload.files)"
        class="mu-hidden-input">

    <div
        id="image_upload"
        class="btn btn-primary text-center w-100 py-3"
        [style]="'background-image: ' + backgroundImage + '; background-size: contain; background-repeat: no-repeat; background-position: center;'"
        (click)="fileUpload.click()">

        <div class="mu-text-holder">
            <h3>
                <i class="fas fa-upload"></i>
            </h3>

            <b *ngIf="!loading && !inputFileList?.length"> أختار صورة </b>
            <b *ngIf="!loading && inputFileList?.length"> تم أختيار {{inputFileList?.length}} </b>

            <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                <span class="sr-only"></span>
            </div>

        </div>

    </div>
</div>