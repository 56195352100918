import { Injectable } from '@angular/core';
import { CentralService } from 'src/app/services/central.service';

@Injectable({
  providedIn: 'root'
})

export class SchoolAdminService extends CentralService {

  protected resource: string = "school_admin";
  protected admin_prefix: string = "superadmin";

}
