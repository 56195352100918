import { Student } from "./student.interface";

export interface Parent {
    id?: number,
    name: string,
    students?: Student[],
    gender: string,
    phone: string,
    email: string,
    address_details: string,
    created_at?: string,
    updated_at?: string
}

// the default version of the interface is used to reset objects that have that interface
export const DefaultParent = {
    id: undefined,
    name: '',
    students: undefined,
    address_details: '',
    gender: '',
    phone: '',
    email: ''
}