import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  public isEnglish: boolean;
  public LANG_TOKEN: string = 'LANG_TOKEN';
  public selectedLanguage: any;
  
  constructor(public translate: TranslateService){
       
      translate.addLangs(['ar', 'en']);

      this.selectedLanguage = localStorage.getItem(this.LANG_TOKEN);

      if(this.selectedLanguage){
          translate.setDefaultLang(this.selectedLanguage);
          this.isEnglish = (this.selectedLanguage == 'en') ? true : false;
      } else {
          const browserLang = translate.getBrowserLang();
          translate.use(browserLang.match(/ar|en/) ? browserLang : 'ar');
      }

      translate.onLangChange.subscribe((event: LangChangeEvent) => {
          localStorage.setItem(this.LANG_TOKEN, event['lang']);
          translate.setDefaultLang(event["lang"]);
        });
     }    
     

    useLanguage(setEnglish: boolean): boolean {
        if(setEnglish)
            this.translate.use('en');
        else 
            this.translate.use('ar');
        
        this.isEnglish = setEnglish;
        return setEnglish;
    }
}
