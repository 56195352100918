import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class InquiryService extends CentralService {
  
  protected resource: string = "inquiry";
  
  seeAll(data: {parent_school_id: number}): Observable<any> {
    return this.getSpecific('seeAll', data);
  }
}
