import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Editor, Toolbar, toHTML, toDoc } from 'ngx-editor';
import { finalize } from 'rxjs/operators';
import { DefaultNews, News } from 'src/app/interfaces/news.interface';
import { NewsService } from 'src/app/services/resources/news.service';
import * as jsonToFormData from 'json-form-data';
@Component({
  selector: 'app-news-form-modal',
  templateUrl: './news-form-modal.component.html',
  styleUrls: ['./news-form-modal.component.scss']
})
export class NewsFormModalComponent implements OnInit {

  @Input() selectedResourceId: any = null;
  @Output() closeAction = new EventEmitter<boolean>(); 

  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic', 'underline'],
    ['strike', 'blockquote', 'ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image', 'text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  loading: boolean;
  error: boolean;

  fileUploading: boolean;
  uploaderImage: string;
  uploaderFileList: FileList;

  defaultResourceData: News = DefaultNews; 
  resourceData: News =  JSON.parse(JSON.stringify(this.defaultResourceData));

  constructor(private resourceService: NewsService) { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.selectedResourceId && this.getResourceById(this.selectedResourceId);
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  getResourceById(id: any) {
    this.resourceService.getSpecific(id)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.resourceData = response.data, error => this.error = true)
  }

  submitResourceForm(resourceForm: NgForm): void {
    if(this.selectedResourceId) this.submitUpdate(this.selectedResourceId, resourceForm);
    else this.submitNew(resourceForm);
  }

  submitUpdate(id: any, form: NgForm): void {
    let formData = jsonToFormData(form.value);
    if(this.uploaderFileList && this.uploaderFileList[0]) formData.set('image', this.uploaderFileList[0]);

    this.resourceService.patchByPost(id, formData)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }

  submitNew(form: NgForm): void {
    let formData: FormData = jsonToFormData(form.value);
    if(this.uploaderFileList && this.uploaderFileList[0]) formData.set('image', this.uploaderFileList[0]);

    this.resourceService.post(formData)
      .pipe(o => { this.loading = true; return o }, finalize(() => this.loading = false))
      .subscribe(response => this.closeAction.emit(true), error => this.error = true)
  }
  
  getImageIfAny(file: any | string, files: FileList = undefined): void {
    console.log("File: ", this.resourceData, file, files);

    if(!file && (files && !files[0]))
      this.uploaderImage = '';

    if (files && files[0]) {
      var reader  = new FileReader();
      reader.onload = (e) => {
          console.log(`url(${ e.target.result})`);
          this.uploaderImage = `url(${ e.target.result})`;
      }
      reader.readAsDataURL(files[0]);
    } else if (typeof file == 'string') {
      if(file) this.uploaderImage = `url(${file})`;
    }
    
  }

}
