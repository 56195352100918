import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SidebarConfiguration, SidebarSection } from './moudsidebar.interface';

@Component({
  selector: 'moudsidebar',
  templateUrl: './moudsidebar.component.html',
  styleUrls: ['./moudsidebar.component.scss']
})
export class MoudSidebarComponent implements OnInit {

  dropdownIsCollapsed: boolean = false;
  sidebarActive: boolean = false;
  currentRoute: string = '';

  @Input('sidebar_rows') sidebar_routes: SidebarSection[] = [{
    title: 'الصفحة الرئيسية',
    icontype: 'fontawesome' ,
    icon: 'fa-tachometer-alt',
    active: true,
    link: '/'
  }];

  @Input('configuration') configuration: SidebarConfiguration = {
    icontype: 'fontawesome' ,
    header: {
      title: 'Moud Sidebar',
      icon: 'palette',
    },
    footer: {
      button: true,
      title: 'تسجيل الخروج',
      icon: 'sign-out-alt',
    }
  };;
  
  @Output() clicked = new EventEmitter<string>(); 

  constructor(public router: Router) {
      this.setCurrentRoute();
    }

  ngOnInit(): void {
    this.router.events
      .subscribe(event => {
        if(event instanceof NavigationEnd){
          this.setCurrentRoute();
        }
      })
  }

  setCurrentRoute(): void {
    this.currentRoute = window.location.pathname;
  }
}
