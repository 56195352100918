<div class="d-flex">
      
    <button (click)="sidebarActive = !sidebarActive" class="btn btn-collapse" [class.collapsed]="sidebarActive">
        {{sidebarActive ? '☰' : '🗴' }}
    </button>    

    <div class="sidebar" [class.active]="sidebarActive">

        <!-- Sidebar Header section -->
        <a routerLink="">
        <div *ngIf="configuration.header" class="sidebar-header text-center">

            <span class="h1">
                
                <img *ngIf="configuration.header.image" [src]="configuration.header.image"
                    class="sidebar-icon"/>

                <ng-container *ngIf="configuration.header.icon" >

                    <i *ngIf="configuration.icontype == 'fontawesome'" [class]="configuration.header.icon"></i>

                    <ng-container *ngIf="configuration.icontype == 'iconify'">
                        <ic-icon [icon]="configuration.header.icon"></ic-icon>
                    </ng-container>

                </ng-container>

            </span> 
            
            <p class="mt-2 h3 sidebar_text">
                {{ configuration.header.title }}
            </p>

        </div>
        </a>

        <ul class="list-unstyled components">
        <!-- Sidebar route Generation Loop -->
        <ng-container *ngFor="let route of sidebar_routes">
            <li>
                <a *ngIf="!route.subroutes" 
                    (click)="this.clicked.emit(route.title)"
                    [routerLink]="route.active ? (route.link && [route.link]) : undefined" 
                    [queryParams]="route.active && { action: route.queryParam }" 
                    [class]="(!route.active ? 'sidebar_disabled_link' : 'sidebar_link')">
                    
                    <i  *ngIf="configuration.icontype == 'fontawesome'"
                        [class]="route.icon + ' mx-2'"></i>
                    
                    <ng-container *ngIf="configuration.icontype == 'iconify'">
                        <ic-icon [icon]="route.icon" [class]="'mx-2'"></ic-icon>
                    </ng-container>
                    
                    <span class="sidebar_text"> {{ route.title }} </span>

                </a>

                <a *ngIf="route.subroutes"  
                    [class]="(!route.active ? 'sidebar_disabled_link' : 'sidebar_link')" 
                    (click)="route.dropdownActive = !route.dropdownActive">

                    <i *ngIf="configuration.icontype == 'fontawesome'" [class]="route.icon + ' mx-1'"></i>

                        <ng-container *ngIf="configuration.icontype == 'iconify'">
                            <ic-icon [icon]="route.icon" [class]="'mx-1'"></ic-icon>
                        </ng-container>
                    
                    {{ route.title }} 

                    <i *ngIf="configuration.icontype == 'fontawesome'" 
                        [class]="'fas fa-caret-down dropdown_arrow'  + (route.dropdownActive ? ' active' : '')"></i>
                    
                </a>
                
                <div [class]="'dropdown_item_holder' + (route.dropdownActive ? ' active' : '')">

                <!-- Sidebar Subroute Generation Loop -->
                <ng-container *ngFor="let subroute of route.subroutes">
                    <li>
                        <a  [routerLink]="subroute.link && [subroute.link]"
                            (click)="this.clicked.emit(subroute.title)"
                            [class]="'sidebar_item dropdown_item' + (subroute.link == currentRoute ? ' selected' : '') + (!subroute.active ? ' disabled' : '')">
                            
                            <i *ngIf="configuration.icontype == 'fontawesome' && subroute.includeParentIcon" 
                                [class]="route.icon + ' mx-2'"></i>
                                
                            <ic-icon *ngIf="configuration.icontype == 'iconify' && subroute.includeParentIcon" 
                                    [icon]="route.icon" 
                                    [class]="'mx-2'"></ic-icon>
                            
                            <i *ngIf="configuration.icontype == 'fontawesome'"
                                [class]="subroute.icon"></i>

                            <ic-icon *ngIf="configuration.icontype == 'iconify'" 
                                    [icon]="subroute.icon"></ic-icon>
                            
                            <span class="sidebar_text">
                                {{ subroute.title }}
                            </span>

                        </a>
                    </li>
                </ng-container>

                </div>
            </li>
        </ng-container>

            <hr class="mx-5">
            
            <!-- Sidebar Footer Section  -->

            <li *ngIf="configuration.footer">
                <a  class="sidebar_link"
                    *ngIf="configuration.footer.button"
                    [routerLink]="configuration.footer.link && [configuration.footer.link]"
                    (click)="this.clicked.emit(configuration.footer.title)">

                    <i *ngIf="configuration.icontype == 'fontawesome'"  
                        [class]="configuration.footer.icon + ' mx-2'"></i>

                    <ic-icon *ngIf="configuration.icontype == 'iconify'" 
                            [icon]="configuration.footer.icon" 
                            [class]="'mx-2'"></ic-icon>
                    
                    <span class="sidebar_text">
                        {{ configuration.footer.title }}
                    </span>

                </a>
            </li>
        </ul>

    </div>

    <div class="main-content bg-gray">
        <ng-content></ng-content>
    </div>

</div>