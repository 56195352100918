<div class="container">
    <div class="row">
        <div class="col-11 my-5 mx-auto">
            <div class="h3">{{ pageTitle }}</div>
            <div class="btn btn-lg btn-primary p-3" *ngIf="defaultStatus === 'offline'" (click)="toggleStatus()">
                تشغيل النداءات <i class="fas fa-play mx-2"></i>
            </div>
            <div class="btn btn-lg btn-danger p-3" *ngIf="defaultStatus === 'online'" (click)="toggleStatus()">
                إيــقــاف الـنـداءات <i class="fas fa-pause mx-2"></i>
            </div>
        </div>
    </div>

    <div class="col-11 mx-auto" *ngIf="true">
        <div class="card overflow-auto p-3">
            <div class="row my-2 mx-2 text-center">
                <div class="col-md-1 col-2 px-0">
                    <span *ngIf="!isLoading" class="h1 text-primary">{{ resourceList ? resourceList.length : '...' }}</span>
                    <div *ngIf="isLoading" class="spinner-border text-primary p-3 spinner-border-sm" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <div style="width: fit-content" class="px-0 h1 text-primary"><b>|</b></div>
                <div class="col-md-1 col-2 px-0 pt-1 text-primary">
                    <div class="h6">إجمالي</div>
                    <div class="h6">النداءات</div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <div class="row" *ngFor="let resource of resourceList">
                        <div class="col-lg-3 col-md-4 col-6 p-1 text-center">
                            <div class="h5 mb-0 pb-0">{{ resource.parent.name }}</div>
                            <span class="badge bg-primary" *ngFor="let student of resource.parent.students">{{ student.name }}</span>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6 p-1 text-center">
                            <span class="badge bg-primary" *ngIf="resource.status == 'pending'">
                                قيد الإنتظار
                            </span>
                            <span class="badge bg-success" *ngIf="resource.status == 'approved'">
                                تمت الموافقة
                            </span>
                            <span class="badge bg-danger" *ngIf="resource.status == 'declined'">
                                تم الرفض
                            </span>
                            <div class="h6">{{resource.updated_at | date: 'short'}}</div>
                        </div>
                        <div class="col-lg-2 col-md-4 col-6 p-1 text-center">
                            <div class="h6 mb-0 pb-0">{{ 'النداء من أجل' }}</div>
                            <span class="badge bg-primary">{{ getNames(resource.student_call, true) }}</span>
                        </div>
                        <div class="col-lg-4 col-md-12 d-flex justify-content-center align-items-center">
                            <audio class="audio_player" [id]="resource.id" width="100%" controls>
                                <source [src]="resource.call_url" type="audio/mp3">
                            </audio>
                        </div>
                        <hr class=" my-3">
                    </div>

                    <paginator-component
                        [metadata]="metadata"
                        (getNewPage)="getAllCalls(this.selectedSorting, $event)">
                    </paginator-component>
                </div>
            </div>

            <loader-section
                [loading]="false"
                [data]="resourceList"
                [error]="isError">
            </loader-section>

        </div>
    </div>

</div>
