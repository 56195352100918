import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { DefaultPeriod, Period } from 'src/app/interfaces/period.interface';
import { PeriodService } from 'src/app/services/resources/period.service';

@Component({
  selector: 'app-periods-main-screen',
  templateUrl: './periods-main-screen.component.html',
  styleUrls: ['./periods-main-screen.component.scss']
})
export class PeriodsMainScreenComponent implements OnInit {

  isLoading: boolean = false;
  isError: boolean = false;
  pageTitle: string = 'إدارة الفترات';

  resourceList: Period[] = undefined;
  defaultResourceShape: Period = DefaultPeriod;
  metadata: MetaData;

  selectedResourceId: number = undefined;
  selectedResourceData: Period = undefined;

  schoolLevels: any[] = [
    {id: 1, label: "الإبتدائي"},
    {id: 2, label: "الإعدادي"},
    {id: 3, label: "الثانوي"}
  ];

  filter: any = {
    name: undefined,
    schoolLevel: undefined
  }

  constructor(private resourceService: PeriodService,
              private ngbModal: NgbModal) { }


  ngOnInit(): void {
    this.getAllResources();
  }

  getAllResources(page: number = 1, filter: any = {}): void {
    this.resourceService.getAll({page: page, ...filter})
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: any) => {
        this.resourceList = response.data;
        this.metadata = response;
      }, (error) => this.isError = true)
  }

  search() {
    let query = {};

    if(this.filter.name)
      query['filter[name]'] = this.filter.name;
    if(this.filter.schoolLevel)
      query['filter[classes.school_level_id]'] = this.filter.schoolLevel;
    
    this.getAllResources(1, query);
  }

  openResourceModal(content: HTMLElement, type: string, id: any = undefined): void {
    if(id) {
      this.selectedResourceId = id;
      this.selectedResourceData = this.cloneObject(this.resourceList.filter((resource) => resource.id == id)[0]);
    }

    this.ngbModal
      .open(content, { size: ((type == "delete") ? undefined : 'lg'),  centered: true, beforeDismiss: () => {
        this.selectedResourceId = undefined;
        this.selectedResourceData = this.cloneObject(this.defaultResourceShape);
        this.getAllResources();
        return true;
      } })
  }

  cloneObject(item: any): any {
    return JSON.parse(JSON.stringify(item));
  }

  closeConfirmModal(modal:NgbActiveModal, confirmed:boolean): void {
    if(confirmed) this.deleteResource();
    modal.close(confirmed);
  }

  deleteResource(): void {
    this.resourceService.delete(this.selectedResourceId)
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: Response) => {
        this.resourceList = response['data'];
        this.selectedResourceId = undefined;
        this.getAllResources();
      }, error => {
        this.isError = error;
        console.warn(error);
      })
  }

}
