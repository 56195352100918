<div class="fluid-container p-3">
    <div class="row">
        <div class="col-11 mx-auto">
            <h3>مستخدم مدرسة</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-11 mx-auto">
            <form #resourceForm="ngForm" (submit)="submitResourceForm(resourceForm)">
                <div class="row">
                    <div class="col-md-6 col-12 my-3">
                        <input required dir="rtl"
                            type="text"
                            name="name"
                            [(ngModel)]="resourceData.name"
                            placeholder="الإسم"
                            class="form-control">
                    </div>  
                    <input type="text" name="status" hidden [(ngModel)]="resourceData.status">  
                    <div class="col-md-6 col-12 my-3">
                        <input required dir="rtl"
                            type="email"
                            name="email"
                            [(ngModel)]="resourceData.email"
                            placeholder="البريد الإلكتروني"
                            class="form-control">
                    </div> 
                    <div class="col-12 my-3">
                        <div class="btn btn-primary"
                            *ngIf="selectedResourceId"
                            (click)="editPassword = !editPassword">
                            تعديل الكلمة السرية
                        </div>
                    </div>
                    <div class="col-md-6 col-12 my-3" *ngIf="!selectedResourceId || editPassword">
                        <input required dir="rtl"
                            type="password"
                            name="password"
                            [(ngModel)]="resourceData.password"
                            placeholder="الرمز السري"
                            class="form-control">
                    </div> 
                    <div class="col-md-6 col-12 my-3" *ngIf="!selectedResourceId || editPassword">
                        <input required dir="rtl"
                            type="password"
                            name="password_confirmation"
                            [(ngModel)]="resourceData.password_confirmation"
                            placeholder="تأكيد الرمز السري"
                            class="form-control">
                            <label for="" class="text-danger"
                                *ngIf="resourceData.password != resourceData.password_confirmation">
                                الرموز السرية يجب أن تتشابه
                            </label>
                    </div> 
                </div>
                
                <div class="row py-3">
                    <div class="row w-100">
                        <div class="col-md mx-auto" [class.d-none]="selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading"
                                class="btn btn-primary text-light w-100">
                                {{ loading ? '' : 'إضافة' }}

                                <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>

                            </button>
                        </div>
                        
                        <div class="col-md mx-auto" [class.d-none]="!selectedResourceId">
                            <button type="submit"
                                [disabled]="!resourceForm.valid || loading"
                                class="btn btn-primary text-light w-100">
                                {{ loading ? '' : 'تعديل' }}
                                
                                <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                </div>

                            </button>
                        </div>
                        <div class="col-md mx-auto">
                            <button type="button"
                                class="btn btn-danger text-light w-100"
                                (click)="closeAction.emit(true)">إلغاء</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>