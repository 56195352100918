import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { SchoolLevels, SchoolLevelsGuide } from 'src/app/interfaces/class.interface';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { ClassService } from 'src/app/services/resources/class.service';

@Component({
  selector: 'app-class-main-screen',
  templateUrl: './class-main-screen.component.html',
  styleUrls: ['./class-main-screen.component.scss']
})
export class ClassMainScreenComponent implements OnInit {

  isLoading: boolean = false;
  isError: boolean = false;
  pageTitle: string = 'تسجيل الفصول';

  resourceList: SchoolLevels[] = undefined;
  metadata: MetaData = undefined;

  selectedResourceId: number = undefined;

  yearsData: SchoolLevelsGuide[] = [
    {level: '1', name: 'الإبتدائي', years: [1,2,3,4,5,6]},
    {level: '2', name: 'الإعدادي', years: [1,2,3]},
    {level: '3', name: 'الثانوي', years: [1,2,3]}
  ]

  constructor(private resourceService: ClassService,
              private ngbModal: NgbModal) { }


  ngOnInit(): void {
    this.getAllResources();
  }

  getAllResources(): void {
    this.resourceService.getAllByClass()
    .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
    .subscribe((response: any) => {
        this.resourceList = response.data;
        this.metadata = response.meta;
      }, (error) => this.isError = true)
  }

  openResourceModal(content: HTMLElement, type: string, id: any = undefined): void {
    console.log("resource modal ", type, id)
    if(id) this.selectedResourceId = id;
    
    this.ngbModal
      .open(content, { size: ((type == "delete") ? undefined : 'xl'),  centered: true, beforeDismiss: () => {
        this.selectedResourceId = undefined;
        this.getAllResources();
        return true;
      } })
  }
  
  cloneObject(item: any): any {
    return JSON.parse(JSON.stringify(item));
  }

  closeConfirmModal(modal:NgbActiveModal, confirmed:boolean): void {
    if(confirmed) this.deleteResource();
    modal.close(confirmed);
  }

  deleteResource(): void {
    this.resourceService.delete(this.selectedResourceId)
      .pipe(ob => {this.isLoading = true; return ob}, finalize(() => this.isLoading = false))
      .subscribe((response: Response) => {
        this.resourceList = response['data'];
        this.selectedResourceId = undefined;
        this.getAllResources();
      }, error => {
        this.isError = error;
        console.warn(error);
      })
  }
}
