import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { DefaultInquiry, Inquiry } from 'src/app/interfaces/inquiry.interface';
import { MetaData } from 'src/app/interfaces/metadata.interface';
import { InquiryService } from 'src/app/services/resources/inquiry.service';
import { ParentService } from 'src/app/services/resources/parent.service';

@Component({
  selector: 'app-inquiries-main-screen',
  templateUrl: './inquiries-main-screen.component.html',
  styleUrls: ['./inquiries-main-screen.component.scss']
})
export class InquiriesMainScreenComponent implements OnInit {

  isLoading: boolean = false;
  isError: boolean = false;
  pageTitle: string = 'الإستفسارات';

  resourceList: Inquiry[] = undefined;
  defaultResourceShape: Inquiry = DefaultInquiry;
  metadata: MetaData;

  selectedResourceId: number = undefined;
  selectedResourceData: Inquiry = undefined;

  constructor(private inquiryService: InquiryService,
              private parentService: ParentService,
              private ngbModal: NgbModal) { }


  ngOnInit(): void {
    this.getAllChats();
  }

  getAllChats(page: number = 1): void {
    this.isLoading = true;

    this.parentService.getLatestChats({page: page})
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(response => {
        this.resourceList = response.data;
        this.metadata = response;
      }, error => this.isError = true)
  }

  getAllResources(): void {
    this.isLoading = true;

    this.inquiryService.getAll()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((response: any) => this.resourceList = response.data, (error) => this.isError = true)
  }

  openResourceModal(content: HTMLElement, type: string, id: any = undefined): void {
    if(id) {
      this.selectedResourceId = id;
      this.selectedResourceData = this.cloneObject(this.resourceList.filter((resource) => resource.id == id)[0]);
    }
    
    this.ngbModal
      .open(content, { size: ((type == "delete") ? undefined : 'xl'),  centered: true, beforeDismiss: () => {
        this.selectedResourceId = undefined;
        this.selectedResourceData = this.cloneObject(this.defaultResourceShape);
        this.getAllChats();
        return true;
      } })
  }
  
  cloneObject(item: any): any {
    return JSON.parse(JSON.stringify(item));
  }

  closeConfirmModal(modal:NgbActiveModal, confirmed:boolean): void {
    if(confirmed) this.seenChat();
    modal.close(confirmed);
  }

  seenChat(): void {
    this.isLoading = true;

    this.inquiryService.seeAll({ parent_school_id: this.selectedResourceId })
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((response: Response) => this.getAllChats(), error => this.isError = error)
  }

}
